
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { generateOTP, verifyOTP, postErrorLog } from '../Services/api';
import NavHeader from './NavHeader';
import Loader from './Loader';
const UserLogin = () => {
  // PS_Otp_02: SQ_Otp_1.2 - useState
 
  const [otp, setOtp] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isValidAccess, setIsValidAccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  // PS_Otp_03: SQ_Otp_1.3 - useSearchParams
  const [searchParams] = useSearchParams();
  const emailId: any | null  = searchParams.get('emailId');
  const assessmentId: any | null  = searchParams.get('assessmentId');

  // PS_Otp_04: SQ_Otp_1.4 - useEffect
  useEffect(() => {
    if (emailId || assessmentId) {
      setIsValidAccess(true);
      initiateOtpGeneration();
    } else {
      setIsValidAccess(false);
      setError('Invalid access. Please use the correct login link.');
      // Optionally, redirect to an error page
      // navigate('/error');
    }
  }, []);

  // Helper function implementations
  // PS_Otp_05 - PS_Otp_14
  const initiateOtpGeneration = async () => {
    if (!emailId || !assessmentId) {
      // PS_Otp_14
      return;
    }
    try {
      setLoading(true); // PS_Otp_06
      setError(''); // PS_Otp_07
      setOtp('');

      // PS_Otp_08 & PS_Otp_09
      await generateOTP(emailId, assessmentId);

      // PS_Otp_10 
      // ... Update UI or state as necessary, depending on OTP generation result

    } catch (error:any) {
        let errorData:object = {
          errorFunction: "initiateOtpGeneration",
            errorMessage: error.message,
            FileName: "UserLogin.tsx"
        }
        
      // PS_Otp_11 & PS_Otp_12
      await postErrorLog(errorData);
      setError('Failed to generate OTP. Please try again.'); // PS_Otp_24
    } finally {
      setLoading(false); // PS_Otp_13
    }
  };
 
  //PS_Otp_16 - PS_Otp_27
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // PS_Otp_17
    if (!otp) {
      setError('Please enter the OTP.'); // PS_Otp_27
      return;
    }

    try {
      setLoading(true); // PS_Otp_20
      setError(''); // Resetting the existing error messages

      // PS_Otp_21 & PS_Otp_22
      const verificationResult = await verifyOTP(otp, assessmentId, emailId);
      const responseData = verificationResult.data as { success: boolean; status: string; message: string; otp: string };
      // PS_Otp_23
      if (responseData.success && responseData.message === "Successfully authenticated") {
        // Redirecting the user to the downloadURLPage if OTP is correct
        navigate('/downloadURLPage', { state: { emailId, assessmentId } });
      } else {
        // PS_Otp_24
        setError('Invalid OTP. Please try again.');
      }
    } catch (error:any) {
        let errorData:object = {
          errorFunction: "initiateOtpGeneration",
          errorMessage: error.message,
          FileName: "UserLogin.tsx"
        }
        
      // PS_Otp_11 & PS_Otp_12
      await postErrorLog(errorData);
      setError('An error occurred during OTP verification.');
    } finally {
      setLoading(false); // PS_Otp_26
    }
  };

  // PS_Otp_23: SQ_Otp_1.20 - useNavigate
  const navigate = useNavigate();

  // PS_Otp_28: SQ_Otp_1.25 - Render Method
  return (
    <>
    <Loader isLoading={loading} />
    <div className="container-fluid">
    <div className="row h-100 login-page">
      <div className="col-6">
        <div className="d-flex align-items-center">
          <img src="images/logo.svg" className="logo-img me-2" alt="site logo" />
          <span className="font-medium font-18 primary-text-color">Win Remedy</span>
        </div>

        <div className="px-5 verification-code-section">
          <h3 className="primary-text-color font-40 font-semibold">Verify code</h3>
          <p className="font-16 font-regular mt-3 grey-text-color-dark">
            An authentication code has been sent to your email.
          </p>
          
          <div className="mt-5 position-relative">
            <div className="form-floating">
            <input 
  id="Password" 
  type={showPassword ? "text" : "password"} 
  className="form-control custom-password-field" // Added custom-password-field class
  placeholder="Enter Code"
  value={otp}
  onChange={(e) => setOtp(e.target.value)}
/>
              <label className="form-label font-14 font-regular" htmlFor="floating-code">
                Enter Code
              </label>
              <img 
                src={showPassword ? "images/pass-hidden.svg" : "images/pass-open.svg"} 
                className="pass-eye" 
                alt={showPassword ? "hide password" : "show password"}
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {error && <span className="required font-11 font-regular d-block">{error}</span>}
          </div>

          <p className="font-14 font-regular primary-text-color text-end mt-3 mb-4 pb-2">
            Didn't receive a code? <a href="#" className=""  onClick={initiateOtpGeneration}>Resend</a>
          </p>

          <button className="primary-btn" onClick={handleSubmit}>Verify</button>
        </div>
      </div>

      <div className="col-6 banner-right">
        <img 
          src="images/welcome-banner.png" 
          className="login-banner-img" 
          alt="login page banner image"
        />
      </div>
    </div>
  </div>
  </>
);
};

export default UserLogin;