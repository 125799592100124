
// LoginAuth.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLogin, postErrorLog, postLogData } from '../Services/api';
import { AxiosResponse, AxiosError } from 'axios';
import Loader from './Loader';

const LoginAuth: React.FC = () => {
    const search = window.location.search;
    let code = new URLSearchParams(search).get("code") || "";
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    //PS_WL_10 - PS_WL_14
    useEffect(() => {
        let handleError = new URLSearchParams(search).get("error");

        if (handleError) {
            localStorage.clear();
            navigate("/");
            return; // Exit the effect immediately to prevent any further actions
        }

        // if (!code) {
        //     navigate("/");
        //     return; // Exit if the code is not present
        // }

        const token = localStorage.getItem("token");
        if (!token) {
            getDetails();
        } else {
            navigate("/organization");
        }
    }, [navigate, code]);
    
    //PS_WL_23 - PS_WL_25 & PS_WL_27 - PS_WL_33
    const getDetails = async () => {
        try {
            setIsLoading(true);
            //PS_WL_26
            const response: AxiosResponse = await getLogin(code);

            const userLog = {
                userName: response?.data?.Claims?.name,
                userActivity: "Login",
            };
            localStorage.setItem("emailId", response?.data.Claims.upn);
            localStorage.setItem("userName", response?.data.Claims.name);
            localStorage.setItem("displayName", response?.data.Claims.app_displayname);
            localStorage.setItem("token", "Bearer " + response?.data.token);
            localStorage.setItem("refreshToken", response?.data.refreshToken);
            localStorage.setItem("rfExpiry", response?.data.expiresOn);

            await postUserLogDetails(userLog);
            redirectURI();
        } catch (error: any) {
            let errorData: object = {
                errorFunction: "getDetails",
                errorMessage: error.message,
                FileName: "LoginAuth.tsx"
            }

            // PS_WL_34
            await postErrorLog(errorData);
            navigate('/');
        }
        finally{
            setIsLoading(false);
          }
    };

    //PS_WL_36, PS_WL_37 & PS_WL_39 - PS_WL_42
    const postUserLogDetails = async (userLog: object) => {
        try {
             setIsLoading(true);
            //PS_WL_38
            const response: AxiosResponse = await postLogData(userLog);
            if (response.status === 400 || response.status === 500) {
                navigate('/');
                return;
            }
        } catch (error: any) {
            let errorData: object = {
                errorFunction: "postUserLogDetails",
                errorMessage: error.message,
                FileName: "LoginAuth.tsx"
            }

            // PS_WL_43
            await postErrorLog(errorData);
        }
        finally{
            setIsLoading(false);
          }
    };
    //PS_WL_44 & PS_WL_45
    const redirectURI = () => {
        navigate('/organization');
    };

    return (
        <div>
            <Loader isLoading={isLoading} />
        </div>
    );
};

export default LoginAuth;