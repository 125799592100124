// PS_UA_02: Create and export unSupportAppsComponent(props: unSupportedAppsProps)
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { unSupportedAppsProps,UnsupportedData } from '../Interface/AssessmentDetailInterface';
import ExcelJS from 'exceljs';
import { getUnsupportedApps, postErrorLog } from '../Services/api';
import Loader from './Loader';
import Tippy from "@tippyjs/react";
import { followCursor } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
//PS_UA_01
 export const UnSupportAppsComponent =({closePopup,assesmentId}:unSupportedAppsProps)=>{

  // PS_UA_02: Declare state variable unsupportedAppsData 
  const [unsupportedAppsData, setUnsupportedAppsData] = useState<UnsupportedData[]>([    {
    userName: "",
    deviceId: "",
    incompatibleApps: [""]
  }]);
  const [loading, setLoading] = useState(false);
  // PS_UA_04: Implement useEffect hook for data fetching
  useEffect(() => {
   pageLoad()
    
  }, []);

  //PS_UA_05: Define asynchronous method pageLoad() to fetch device data
  const pageLoad = async () => {
    try{
        // PS_DD_04: Create request parameters object using current state values
        const pageLoadBO = {
          AssessmentId : assesmentId?.toString(),
        };
        setLoading(true);
           let response = await getUnsupportedApps(pageLoadBO);
           setLoading(false);
           if(response.data!=null && response.data!=undefined){
            setUnsupportedAppsData(response.data);  
           }
           
    } catch (error:any) {
      let errorData:object = {
          errorFunction: "pageLoad",
          errorMessage: error.message,
          FileName: "UnSupportedAppComponent.tsx"
      }
      
    // PS_Otp_11 & PS_Otp_12
    await postErrorLog(errorData);
      setLoading(false);
    }

  };


  // PS_UA_08: Implement bindGrid method to map through unsupportedAppsData
  
  const bindGrid = () => {
    if (unsupportedAppsData.length === 0 || !unsupportedAppsData[0].deviceId) {
      return null;
    }
  
    return unsupportedAppsData.map((data, index) => {
      return (
        <tr key={index}>
          <td className="text-start">{data.userName}</td>
          <td className="text-start">{data.deviceId}</td>
          <td className="text-start">
            {bindApps(index)}
          </td>
        </tr>
      );
    });
  };
  // PS_UA_24-PS_UA_40: Implement bindApps method to create tooltip with character count check 
  const bindApps = (ind:number) => {

    let indata = unsupportedAppsData[ind].incompatibleApps;
    let applength = 0;
    let display = "";
    let tooltipdisplay = "";

    const maxCharacterLimit = 40;

    // Initialize variables to keep track of displayed apps
    let displayedApps: string[] = [];
    let currentLength = 0;
    let tooltipApps: string[]=[];
    // Iterate through the apps
    for (let app of indata) {
        if (currentLength + app.length + 2 <= maxCharacterLimit) { // +2 for ', '
            displayedApps.push(app);
            tooltipApps.push(app)
            currentLength += app.length + 2;
        } else {
          tooltipApps.push(app)
           // break;
        }
    }

    // Calculate the number of apps not displayed
    applength = indata.length - displayedApps.length;

    //PS_UA_37 Create the display string
    display = displayedApps.join(', ');
    let joinedApps: string = tooltipApps.join(', ');

if (joinedApps.length > 150) {
  joinedApps = joinedApps.substring(0, 200) + '...';
}
    
    if (applength > 0) {
        display += '...';
    }
        return (

          <>
          <div className="d-flex align-items-baseline">
            {display}
            {applength > 0 ? (
              <Tippy
                delay={500}
                arrow={true}
                followCursor={"horizontal"}
                plugins={[followCursor]}
                placement="top"
                content={joinedApps}
              >
                <button
                  type="button"
                  className="btn table-app-tooltip custom-tooltip"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-custom-class="custom-tooltip"
                  // title={joinedApps}
                >
                  +{applength}
                </button>
              </Tippy>
            ) : null}
          </div>
        </>
   
        );
    //}

  };

  // PS_UA_10-21: Implement exportToExcelClick method using exceljs
  
  const exportToExcelClick = async () => {
    // PS_UA_11: Create workbook instance
    const workbook = new ExcelJS.Workbook();
  
    // PS_UA_12: Create worksheet instance
    const worksheet = workbook.addWorksheet('Unsupported Apps');
  
    // PS_UA_13: Define columns
    worksheet.columns = [
      { header: 'UserName', key: 'userName', width: 30 },
      { header: 'DeviceID', key: 'deviceID', width: 15 },
      { header: 'IncompatibleApps', key: 'incompatibleApps', width: 50 },
    ];
  
    // Apply bold style to the header row
    worksheet.getRow(1).font = { bold: true };
  
    // PS_UA_14: For each unsupportedAppsData, addRow with data
    if (unsupportedAppsData) {
      unsupportedAppsData.forEach((data, index) => {
        const rowIndex = index + 2; // +2 because Excel is 1-indexed and we have a header row
        
        let apps: string;
        if (Array.isArray(data.incompatibleApps)) {
          apps = data.incompatibleApps.join('\n');
        } else if (typeof data.incompatibleApps === 'string') {
          apps = data.incompatibleApps;
        } else {
          apps = String(data.incompatibleApps); // Convert to string if it's neither array nor string
        }
  
        const row = worksheet.addRow({
          userName: data.userName,
          deviceID: data.deviceId,
          incompatibleApps: apps,
        });
  
        // Set wrap text and vertical alignment for all cells in the row
        row.eachCell((cell) => {
          cell.alignment = { 
            vertical: 'top', 
            wrapText: true 
          };
        });
      });
  
      // Auto-fit rows
      worksheet.eachRow((row, rowNumber) => {
        const cell = row.getCell(3);
        const cellValue = cell.value;
        if (typeof cellValue === 'string') {
          row.height = 15 * (cellValue.split('\n').length + 1);
        }
      });
    }
  
    // PS_UA_15-20: Write buffer and convert buffer to blob
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
    // PS_UA_21-22: Create anchor element, set href and download attributes, trigger click event, and revoke URL
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Unsupported Apps.xlsx';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
<>  
<Loader isLoading={loading} /> 

<div
  className="modal fade show"
  id="staticBackdrop-3"
  
  data-bs-keyboard="false"
  tabIndex={-1}
  aria-labelledby="staticBackdropLabel"
  style={ {display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
  aria-modal="true"
  role="dialog"
>
  <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-full">
    <div className="modal-content border-0">
      <div className="modal-header border-0 p-4 ">
        <h1
          className="modal-title font-16 font-medium table-title-color"
          id="staticBackdropLabel"
        >
          Device with Unsuited Apps
        </h1>
        <button
          type="button"
          className="btn shadow-none px-0"

          onClick={closePopup}
        >
          <img
            src="images/popup-close icon.svg"
            alt="close-icon"
            className="icon-hover shadow-none modal-close-filter"
          />
        </button>  
      </div>
      <div className="modal-body px-4 pt-0">
        <div className="row">
          <div className="col-md-12">
<div className="table-responsive table-border custom-table popup-table-height">
  <table className="table">
    <thead>
      <tr>
        <th className="text-start border-0">Username</th>
        <th className="text-start border-0">Device ID</th>
        <th className="text-start border-0">Incompatible Apps</th>
      </tr>
    </thead>
    <tbody>
      {unsupportedAppsData.length > 0 && unsupportedAppsData[0].deviceId ? (
        bindGrid()
      ) : (
        <tr className="border-transparent">
          <td colSpan={3}>
            <div className="d-flex align-items-center justify-content-center flex-column py-5">
              <img src="images/no-data-found-img.svg" className="mb-5" alt="no-data-found" />
              <p className="font-semibold font-16 text-dark mb-4">No device Found</p>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>
            <div className="d-flex align-items-center justify-content-end mt-3 mb-3">
              <button
                className="outline-btn popup-btn-v2  me-2"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closePopup}
              >
                Cancel
              </button>
              <button
                className="primary-btn popup-btn-v2  ms-2"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={exportToExcelClick}
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




</>
  );
}
