import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import UserLogin from './Component/UserLogin';
import UrlDownload from './Component/UrlDownload';
import Login from './Component/Login';
import LoginAuth from './Component/LoginAuth';
import NavHeader from './Component/NavHeader';
import OrganizationGrid from './Component/OrganizationGrid';
import AddEditForm from './Component/AddEditForm';
import { DeviceDetailComponent } from './Component/DeviceDetailComponent';
import { UnSupportAppsComponent } from './Component/UnSupportedAppComponent';
import Dashboard from './Component/Dashboard';



function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/userLogin" element={<UserLogin />}> </Route>
          <Route path="/download" element={<UrlDownload />}> </Route>
          <Route path="/" element={<Login />}> </Route>
          <Route path="/winremedylogin" element={<LoginAuth />}> </Route>
          <Route path="/navHeader" element={<NavHeader/>}> </Route>
          <Route path="/organization" element={<OrganizationGrid/>}> </Route>
          <Route path="/dashboard" element={<Dashboard/>}> </Route>
          <Route path='/device' element={<DeviceDetailComponent/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
