
// Login.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
const Login: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //PS_WL_03 & PS_WL_04
  const adminLogin = () => {
    const tenantId = process.env.REACT_APP_TENANT_ID
    const clientId = process.env.REACT_APP_CLIENT_ID
    setIsLoading(true);
    window.location.href = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?
        client_id=${clientId}&response_type=code&redirect_uri=${window.location.origin}/winremedylogin&scope=https://graph.microsoft.com/.default&prompt=select_account`;
  };
  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="container-fluid">
        <div className="row h-100 login-page">
          <div className="col-6 banner-left">
            <div className="d-flex align-items-center">
              <img src="images/logo.svg" className="logo-img" alt="site logo" />
              <span className="font-medium font-32 primary-text-color">Win Remedy</span>
            </div>
            <button type="button" className="login-btn d-flex border-0 justify-content-center align-items-center" onClick={() => { adminLogin() }}>
              <img src="images/microsoft logo.svg" className="microsoft-logo-img me-2" alt="microsoft logo" />
              Login with Microsoft
            </button>
          </div>
          <div className="col-6 banner-right">
            <img src="images/welcome-banner.png" className="login-banner-img" alt="login page banner image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;