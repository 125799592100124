
import { AxiosResponse } from 'axios';
import { client, DeviceClient,Dashboardclient  } from './client';
import { ExportRequest  } from '../Interface/GridInterface';
import { DashboardData, DeviceDetailsData, ErrorDetails  } from '../Interface/dashboardData';
import { DeviceDetailConstant,getUnsupportedAppsConst,API_BASE_URL, GET_DASHBOARD_DATA_ENDPOINT, GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT, ERROR_LOG_ENDPOINT } from '../Constant';



// PS_SOt_01: SQ_SOt_1.1 - generateOTP
export const generateOTP = async (emailId: string, assessmentId: number):Promise<AxiosResponse<object>> => {
  try {
    // PS_SOt_02 & PS_SOt_03
    const response:AxiosResponse = await client('/generate-otp', { emailId, assessmentId }, 'POST');
    
    // PS_SOt_04
    return response;

  } catch (error) {
    // PS_SOt_05
    console.error("Error generating OTP:", error);
    throw error;
  }
};

// PS_SOt_06: SQ_SOt_1.5 - verifyOTP
export const verifyOTP = async (otp: string, assessmentId: string, emailId: string):Promise<AxiosResponse<object>> => {
  try {
    // PS_SOt_07 & PS_SOt_08
    const response:AxiosResponse = await client('/userLogin', { emailId, assessmentId, otp }, 'POST');
    
    // PS_SOt_09
    return response;

  } catch (error) {
    // PS_SOt_10
    console.error("Error verifying OTP:", error);
    throw error;
  }
};

// PS_SOt_11: SQ_SOt_1.9 - postErrorLog
export const postErrorLog = async (errorData:object):Promise<AxiosResponse<object>> => {
  try {
    // PS_SOt_12 & PS_SOt_13
    const response:AxiosResponse = await client('/errorLog', errorData, 'POST');
    
    // PS_SOt_14
    return response;

  } catch (error) {
    // PS_SOt_15
    console.error("Error logging error:", error);
    throw error;
  }
};

// Method to fetch URL types
//PS_SUR_01 - PS_SUR_10
export async function fetchUrlTypes():Promise<AxiosResponse<object>> {
    try {
      // Enter try block
      const data:AxiosResponse = await client('/getApplicationUrls',{},'GET'); // Call client GET method
      // Process response data
      return data; // Return response data to Component
    } catch (error) {
      // If exception occurs, log error to console
      console.error('Error in fetchUrlTypes:', error);
      // Throw new Error with message
      throw new Error('Failed to fetch URL types');
    }
  }
  
  // Method to download URL type
  //PS_SUR_11 - PS_SUR_20
  export async function downloadUrlType(downloadRequest: object): Promise<AxiosResponse<ArrayBuffer>> {
    try {
      
      const data: AxiosResponse<ArrayBuffer> = await client('/downlaodObject',  downloadRequest , 'POST', 'arraybuffer');
      return data;
    } catch (error) {
      console.error('Error in downloadUrlType:', error);
      throw new Error('Failed to download URL type');
    }
  }
//PS_SW_01 - PS_SW_08
  export const postLogData = async (userLog:object):Promise<AxiosResponse<object>>  => {
    try {
        const response:AxiosResponse = await client('/userLog', userLog , 'POST');
        return response;
    } catch (error) {
        throw error;
    }
};
//PS_SEx_16 - PS_SEx_22
export const uploadObject = async (file: File): Promise<AxiosResponse<object>> => {
  let formData = new FormData();
  formData.append("file", file, file.name);
  
  try {
    const response: AxiosResponse = await client('/uploadObject', formData, 'POST');
    return response;
  } catch (error:any) {
    console.error("Error in uploadObject:", error);
    throw error;
  }
};
// Function to get login data
//PS_SW_09 - PS_SW_16
export const getLogin = async (code: string):Promise<AxiosResponse<any>>  => {
    try {
        const response:AxiosResponse = await client(`/webLogin?code=${code}`, {}, 'GET');
        return response;
    } catch (error) {
        throw error;
    }
};

// PS_SCP_02 - PS_SCP_09
export const fetchOrganizationData = async (requestData: object):Promise<AxiosResponse<object>> => {

  try {
    const response:AxiosResponse =  await client('/getAssessmentsGroupByClientId',  requestData , 'POST');
    return response;
  } catch (error) {
    console.error("Error fetching organization data:", error);
    throw error;
  }
};
// PS_SCP_10 - PS_SCP_16
export const fetchFilterOptions = async ():Promise<AxiosResponse<object>> => {
  try {
    const response:AxiosResponse = await client('/getClientFilterDropdowns',{},'GET');
    return response;
  } catch (error:any) {
    console.error("Error fetching filter options:", error);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error("Data:", error.response.data);
      console.error("Status:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("No response received:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error setting up request:", error.message);
    }
    throw error;
  }
};
// PS_SCP_17- PS_SCP_23
export const getAssessmentDetails = async (params: { organizationId: string, assessmentId: string }):Promise<AxiosResponse<object>> => {
  try {
    const response:AxiosResponse = await client('/getAssessmentInfo',   params , 'POST');
    return response;
  } catch (error) {
    console.error("Error fetching assessment details:", error);
    throw error;
  }
};
// PS_SCP_24 - PS_SCP_30
export const getDeviceDetails = async (updatedExportRequest:object):Promise<AxiosResponse<object>> => {
  try {
    const response:AxiosResponse = await client('/getDeviceByAssessmentId',  updatedExportRequest , 'POST');
    return response;
  } catch (error) {
    console.error("Error exporting report with assessment ID:", error);
    throw error;
  }
};

// PS_SEx_11 - PS_SEx_16
// Define addAssessment function with parameter: formdata
export const addAssessment = async (payload: object):Promise<AxiosResponse<object>> => {
  try {
      // Try to execute the following steps
      
      const response:AxiosResponse = await client('/addClientInfo',  payload , 'POST');
      
      // Return the response received from the server
      return response;
  } catch (error) {
      // If an error occurs, log "Error Add Assessment:" and re-throw error
      console.error("Error Add Assessment:", error);
      throw error;
  }
}

export const getDeviceData = async (data:any) => {

  let config = {
      method: 'POST',
      url: API_BASE_URL +DeviceDetailConstant.getDeviceDetail, 
      headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token"),
      },
      data: data
  }
  let response = await DeviceClient(config);
  return response;
};


export const getUnsupportedApps = async (data:any) => {

  let config = {
      method: 'POST',
      url: API_BASE_URL +getUnsupportedAppsConst.getAppDetail, 
      headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token"),
      },
      data: data
  }
  let response = await DeviceClient(config);
  return response;
};

export const getStatusData = async (data:any) => {

  let config = {
      method: 'POST',
      url: API_BASE_URL +DeviceDetailConstant.getStatusData, 
      headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token"),
      },
      data: data
  }
  let response = await DeviceClient(config);
  return response;
};
export const resendMailService = async (data:any) => {

  let config = {
      method: 'POST',
      url: API_BASE_URL +DeviceDetailConstant.resendMailServiceConst, 
      headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token"),
      },
      data: data
  }
  let response = await DeviceClient(config);
  return response;
};
// Ensure that API_BASE_URL is always a string
const baseUrl = API_BASE_URL || '';
export const postErrorlogs = async (data:any) => {

  let config = {
      method: 'POST',
      url:  baseUrl + ERROR_LOG_ENDPOINT, 
      headers: {
          'Content-Type': 'application/json',
          "Authorization": localStorage.getItem("token"),
      },
      data: data
  }
  let response = await DeviceClient(config);
  return response;
};

 

 
// PS_DB_93: Define the asynchronous function fetchDashboardData that expects an assessmentId parameter and returns a promise.
 
export const fetchDashboardData = async (assessmentId: number): Promise<DashboardData> => {
  try {
    
    // PS_DB_94: Within the function, create a body object encapsulating the assessmentId to send in the API request.
    const body = { assessmentId };
 
    // Ensure both API_BASE_URL and GET_DASHBOARD_DATA_ENDPOINT are defined
    if (!API_BASE_URL || !GET_DASHBOARD_DATA_ENDPOINT) {
      throw new Error('API_BASE_URL or GET_DASHBOARD_DATA_ENDPOINT is not defined');
    }
 
    // Construct the URL correctly
    const url = API_BASE_URL + `${GET_DASHBOARD_DATA_ENDPOINT}`;
 
 
    // PS_DB_95: Execute a POST request using the imported client, passing the constructed URL and the body as arguments.
    const response = await Dashboardclient(url, body, 'POST');
 
    // PS_DB_98: Retrieve the actual dashboard data from the nested data object within the response and return it.
    return response.data.data;
  } catch (error) {
    // PS_DB_99: Implement error handling by catching any issues that occur during the API call process.
    console.error("Error fetching dashboard data:", error);
 
    // PS_DB_100: Return an empty object that matches DashboardData structure in case of an error.
    return {} as DashboardData;
  }
};
 
 
 
export const exportDeviceDetailsData = async (assessmentId: number): Promise<DeviceDetailsData[]> => {
  try {
    // PS_DB_102: Construct a body object with assessmentId and other required properties for the device details request.
    const body = {
      assessmentId: assessmentId,
      loadMore: 0,
      search: "",
    sort : {
        column : "device_name",
        order : "asc"
    },
    filter : {
        manufacturer : "",
        Status : ""
    }
};
// Ensure both API_BASE_URL and GET_DASHBOARD_DATA_ENDPOINT are defined
if (!API_BASE_URL || !GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT) {
  throw new Error('API_BASE_URL or GET_DASHBOARD_DATA_ENDPOINT is not defined');
}
    // PS_DB_103: Execute a POST request using the client, passing the GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT and the body.
    // Construct the URL correctly
    const url = API_BASE_URL + `${GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT}`;

    const response = await Dashboardclient(url, body, 'POST');



    // PS_DB_106: Validate the response structure, ensuring it matches expected format before retrieving device details.
    if (response.data && 
        response.data.data && 
        response.data.data.deviceDetails && 
        Array.isArray(response.data.data.deviceDetails)) {
      return response.data.data.deviceDetails;
    } else {
      // PS_DB_107: In case of an unexpected response structure, return an empty array.
      return [];
    }
  } catch (error) {
    // PS_DB_108: Catch any errors and return an empty array.
    return [];
  }
};

// PS_DB_109: Define the asynchronous function postErrorLogs that takes an errorDetails object and returns a promise that resolves to void.
export const postErrorLogs = async (errorDetails: ErrorDetails): Promise<void> => {
  try {
    // PS_DB_110: Serialize the errorDetails object to JSON format as it is expected by the backend API.
    const body = JSON.stringify(errorDetails);
    // Ensure both API_BASE_URL and GET_DASHBOARD_DATA_ENDPOINT are defined
if (!API_BASE_URL || !ERROR_LOG_ENDPOINT) {
  throw new Error('API_BASE_URL or GET_DASHBOARD_DATA_ENDPOINT is not defined');
}
    // PS_DB_103: Execute a POST request using the client, passing the GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT and the body.
    // Construct the URL correctly
    const url = API_BASE_URL + `${ERROR_LOG_ENDPOINT}`;

    const response = await Dashboardclient(url, body, 'POST');


    // PS_DB_111: Make a POST request using the client, passing the ERROR_LOG_ENDPOINT and the serialized errorDetails object.
  } catch (error) {
    // PS_DB_114: Implement error handling with a catch block.
    // You might want to handle this error in some way, e.g., logging it to a local storage or console.
  }
};

