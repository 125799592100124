
// PS_DB_01: Import React framework specific hooks useState, useEffect and useRef for component state management.
// PS_DB_02: Import hooks like useNavigate from 'react-router-dom' to enable navigation between views.
// PS_DB_03: Import html2canvas for taking screenshots, and jsPDF for generating PDF documents.
// PS_DB_04: Import PieChart and other chart components from 'recharts' library for visualization purposes.
// PS_DB_05: Import interfaces from relative path '../Interfaces/dashboardData' to define data structure.
// PS_DB_06: Import XLSX from 'xlsx' to enable exporting data to Excel format spreadsheets.
import React, { useState, useEffect, useRef} from 'react';
import { fetchDashboardData, exportDeviceDetailsData, postErrorLogs } from '../Services/api';import { useNavigate,useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { PieChart, Pie } from 'recharts';
import ExcelJS from 'exceljs';

import {
  BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, LabelList, ResponsiveContainer, Cell,
} from 'recharts';   
import { DashboardData, DeviceDetailsData,ErrorDetails } from '../Interface/dashboardData';
import NavHeader from './NavHeader';
import { UnSupportAppsComponent } from './UnSupportedAppComponent';
// PS_DB_07: Initialize the functional component using React's Functional Component (React.FC) template for modern React development.
const DashboardComponent: React.FC = () => {
  // PS_DB_08: Use the useNavigate hook to get reference for navigation function that allows redirecting to device details.
  const navigate = useNavigate();
  // PS_DB_09: Setup useState hook to manage deviceDetails state with an empty array as default value.
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetailsData[]>([]);
  const location = useLocation();
    // PS_DB_10- PS_DB_1 2// Accessing the state values

  let { assessmentName, assessmentId, organizationName,managementType } = location.state || {};
   localStorage.setItem('organizationName', organizationName); 
   localStorage.setItem('assessmentName', assessmentName); 
   assessmentId = parseInt(assessmentId)
     // PS_DB_13: Initialize isLoading state with a default value 'true' to indicate loading process is in progress.
  const [isLoading, setIsLoading] = useState(true);
  // PS_DB_14: Setup useState to control the visibility of a popup with a boolean default value.
  const [showPopup, setShowPopup] = useState(false);
  // PS_DB_15: Create state variable 'data' with initial structure defined in DashboardData interface using useState.
  const [data, setData] = useState<DashboardData>({
    totalDevice: 0,
    assessmentProgress: 0,
    deviceReadyToUpgrade: 0,
    deviceWithUnsuitedApps: 0,
    windows11UpgradePath: {
      readyToUpgrade: 0,
      needsReview: 0,
      hardwareRefresh: 0,
      yetToStart: 0,
    },
    applicationCompatibility: {
      totalApplications: 0,
      supportedApps: 0,
      unsupportedApps: 0,
    },
    manufacturerCompatibility: [],
    hardwareCompatibility: [],
  });
  // PS_DB_16: Manage the active tab within the dashboard with useState hook, starting with 'Dashboard' as the default tab.
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [pop, setpop] = useState(false); 
  // PS_DB_17: Utilize useEffect hook to load dashboard data when the component mounts for the first time.
  useEffect(() => {
    loadDashboardData();
  }, []);

  // PS_DB_18: Define asynchronous function loadDashboardData to fetch data from the dashboard using an API call.
  const loadDashboardData = async () => {
    // PS_DB_19: Start loading dashboard data process by setting isLoading true before making an API call.
    setIsLoading(true);
    try {
      // PS_DB_20: Await fetching dashboard data from server using fetchDashboardData function with a parameter.
      const dashboardData = await fetchDashboardData(assessmentId);
      // PS_DB_21: Update the current state of the dashboard with the new data received from the backend API call.
      setData(prevData => {
        if (!dashboardData) {
          return prevData; // Return previous state if dashboardData is undefined
        }
      
        const newData = {
          ...prevData,
          ...dashboardData,
          applicationCompatibility: {
            ...prevData.applicationCompatibility,
            ...(dashboardData.applicationCompatibility
              ? Object.fromEntries(
                  Object.entries(dashboardData.applicationCompatibility)
                    .filter(([_, value]) => value !== 0)
                )
              : {}),
          },
        };
        return newData;
      });
    } catch (err) {
      // PS_DB_22: Handle possible errors during data fetch by using a try-catch block that utilizes handleError function.
      handleError(err,"loadDashboardData")
    } finally {
      // PS_DB_23: Finally, set isLoading state to false indicating that loading has finished, regardless of the result.
      setIsLoading(false);
    }
  };

  // PS_DB_24: Define async function handleError to post error logs and pass error details object to postErrorlogs API.
  const handleError = async (error: any, functionName: any) => {
    const errorDetails: ErrorDetails = {
      errorMessage: error.message,
      errorFunction: functionName,
      errorFileName : "Dashboard.tsx",
    };
  
    // PS_DB_25: Use try-catch block within handleError function to mitigate issues while making a post API call.
    try {
      await postErrorLogs(errorDetails);
    } catch (postError) {
    }
  };

  // PS_DB_26: Define async function fetchDeviceDetails to fetch device specific data from an API.
  const fetchDeviceDetails = async (): Promise<DeviceDetailsData[]> => {
    setIsLoading(true);
      // PS_DB_27: Before fetching data, check if deviceDetails already contains data to avoid unnecessary API calls.
    try {
      if (deviceDetails.length > 0) {
        // If we have cached data, simulate a short delay to show the loader
        await new Promise(resolve => setTimeout(resolve, 500));
        return deviceDetails;
      }
  
      // If no prior data is present, make an API call
      const details = await exportDeviceDetailsData(assessmentId);
      setDeviceDetails(details);
      return details;
    } catch (error) {
      handleError(error, "fetchDeviceDetails");
      return [];
    } finally {
      setIsLoading(false);
    }
  };
  // PS_DB_29: Create an async function exportToExcel with the intent to export device details to an Excel file.
  
  
const exportToExcel = async () => {
  try {
    const details = await fetchDeviceDetails();
    
    const headers = [
      'Username', 'Device Name', 'Status', 'Manufacturer', 'Model', 'System Type', 'Management Type',
      'CPU Processor', 'CPU Process Status', 'CPU Core', 'CPU Core Status', 'HDD', 'HDD Status',
      'TPM Status', 'Memory', 'Memory Status', 'Secure Boot', 'Operating System', 'OS Status',
      'Installed Apps', 'Supported Apps', 'Unsupported Apps'
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Device Details');

    worksheet.addRow(headers).font = { bold: true };

    details.forEach((device) => {
      const { userName, deviceName, status, deviceDetails } = device;
      const { manufacturer, model, systemType, managementType, hardwareDetailsDto, softwareDetailsDto } = deviceDetails || {};
      const { cpuProcessor, cpuProcessStatus, cpuCore, cpuCoreStatus, hdd, hddStatus, tpmStatus, memory, memoryStatus, secureBoot } = hardwareDetailsDto || {};
      const { operatingSystem, operatingSystemStatus, numberOfInstalledApps, numberOfSupportedApps, numberOfUnsupportedApps } = softwareDetailsDto || {};

      const getStatus = (status:any) => status === null ? null : (status ? 'Compatible' : 'Incompatible');
      const getBooleanStatus = (status:any) => status === null ? null : (status ? 'Yes' : 'No');

      // Handle OS Status based on device status and operatingSystemStatus
      const displayOsStatus = status === "Yet to Start" ? null : 
        (operatingSystemStatus === null ? null : 
          (operatingSystemStatus === true ? 'Compatible' : 'Incompatible'));

      worksheet.addRow([
        userName,
        deviceName,
        status,
        manufacturer,
        model,
        systemType,
        managementType === 'UnManaged' ? 'Unmanaged' : managementType,
        cpuProcessor,
        getStatus(cpuProcessStatus),
        cpuCore,
        getStatus(cpuCoreStatus),
        hdd,
        getStatus(hddStatus),
        getBooleanStatus(tpmStatus),
        memory,
        getStatus(memoryStatus),
        getBooleanStatus(secureBoot),
        operatingSystem,
        displayOsStatus, // Use the conditionally set OS Status
        numberOfInstalledApps,
        numberOfSupportedApps,
        numberOfUnsupportedApps
      ]);
    });

    worksheet.columns.forEach(column => column.width = 15);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'DeviceDetails.xlsx';
    link.click();
    URL.revokeObjectURL(link.href);
  }
  catch (error) {
    handleError(error, "exportToExcel");
  }
};

  // PS_DB_34: Define the exportFullReport async function for converting the dashboard data into a comprehensive report.
  
  
  
  const exportFullReport = async () => {
    let originalDisplay = '';
    let dashboardHeader: HTMLElement | null = null;
  
    try {
      await fetchDeviceDetails();
  
      dashboardHeader = document.getElementById('dashboard-header');
      if (dashboardHeader) {
        originalDisplay = dashboardHeader.style.display;
        dashboardHeader.style.setProperty('display', 'none', 'important');
      }
  
      await new Promise(resolve => setTimeout(resolve, 1890));
  
      const captureElement = async (elementId: string): Promise<string | null> => {
        const element = document.getElementById(elementId);
        if (!element) {
          return null;
        }
  
        const wasHidden = element.classList.contains('hidden');
        if (wasHidden) {
          element.classList.remove('hidden');
          element.classList.add('visible');
        }
  
        try {
          const canvas = await html2canvas(element, {
            scale: 2,
            logging: false,
            useCORS: true,
            allowTaint: true,
            onclone: (clonedDoc) => {
              const clonedElement = clonedDoc.getElementById(elementId);
              if (clonedElement) {
                clonedElement.classList.remove('hidden');
                clonedElement.classList.add('visible');
              }
              // Ensure the dashboard header is hidden in the cloned document
              const clonedHeader = clonedDoc.getElementById('dashboard-header');
              if (clonedHeader) {
                clonedHeader.style.setProperty('display', 'none', 'important');
              }
            }
          });
  
          return canvas.toDataURL('image/png');
        } catch (error) {
          handleError(error, "exportFullReport");
          return null;
        } finally {
          if (wasHidden) {
            element.classList.remove('visible');
            element.classList.add('hidden');
          }
        }
      };
  
      const dashboardImgData = await captureElement('dashboard');
      let deviceDetailsImgData = await captureElement('hiddendeviceDetails');
  
      if (!deviceDetailsImgData) {
        const fallbackImage = await createFallbackDeviceDetailsImage();
        deviceDetailsImgData = fallbackImage || null;
      }
  
      const pdf = new jsPDF('l', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
  
      const addImageToPdf = (imgData: string | null) => {
        if (imgData && imgData.startsWith('data:image/png;base64,')) {
          const imgProps = pdf.getImageProperties(imgData);
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.addPage();
        }
      };
  
      addImageToPdf(dashboardImgData);
      addImageToPdf(deviceDetailsImgData);
  
      const pageCount = (pdf as any).internal.getNumberOfPages();
      pdf.deletePage(pageCount);
  
      pdf.save('Dashboard-report.pdf');
  
    } catch (error) {
      handleError(error, "exportFullReport");
    } finally {
      // Restore the original display style of the dashboard header
      if (dashboardHeader) {
        dashboardHeader.style.display = originalDisplay;
      }
    }
  };


  // PS_DB_43: Initiate a fallback mechanism to create an image representation of device details in case the preferred method fails.
  const createFallbackDeviceDetailsImage = async () => {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = 800;
      canvas.height = 600;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = 'black';
        ctx.font = '20px Arial';
        ctx.fillText('Device Details', 20, 40);
        // Add more text with device details here
      }
      return canvas.toDataURL('image/png');
    } catch (error) {
      handleError(error, "createFallbackDeviceDetailsImage");
    }
  };
  
  
  // PS_DB_45: Setup handleClosePopup function which resets the state setShowPopup to false, thereby closing the popup.
  const handleClosePopup = () => {
    try {
      setShowPopup(false);
    } catch (error) {
      handleError(error, "handleClosePopup");
    }
  };
  
  // PS_DB_46: Declare a constant COLORS object that maps 'compatible' and 'incompatible' statuses to specific color codes.
  const COLORS = {
    incompatible: '#E84447',
    compatible: '#4ECA77',
  };
  
  // PS_DB_47: Define the array pieData structure containing data points and fill colors for the PieChart component.
  const pieData = [
    { name: 'Unsupported Apps', value: data.applicationCompatibility.unsupportedApps, fill: '#FF4B55' },
    { name: 'Supported Apps', value: data.applicationCompatibility.supportedApps, fill: '#4ECA77' },
  ];
  
  // PS_DB_48: Write the CustomLabel component to be used by recharts for custom label rendering.
  const CustomLabel = (props: any) => {
    try {
      const { x, y, width, value } = props;
      return (
        <text x={x + width / 2} y={y} dy={-6} fill="#000" textAnchor="middle">
          {value}
        </text>
      );
    } catch (error) {
      handleError(error, "CustomLabel");
      return null;
    }
  };
  
  // PS_DB_49: Craft function calculatePercentage returning a float percentage or a fallback 0 on special cases.
  const calculatePercentage = (value: number, total: number): number => {
    try {
      if (total === 0) return 0;
      const percentage = (value / total) * 100;
      return parseFloat(percentage.toFixed(2)); // Returns a float with 2 decimal places
    } catch (error) {
      handleError(error, "calculatePercentage");
      return 0; // Return 0 as a fallback in case of error
    }
  };

  // PS_DB_50: Establish NoRecordsFound component that provides 'No Records Found' message or error fallback UI.
  const NoRecordsFound: React.FC = () => {
    try {
      return (
        <div className="daskboard-no-record-found-section">
          <img src="images/no-data-found-img.svg" className="mb-4 no-data-found" alt="no-data-found" />
          <p className="font-regular font-13 no-data-color mb-4">No Records Found</p>
        </div>
      );
    } catch (error) {
      handleError(error, "NoRecordsFound");
      // Return a fallback UI in case of error
      return (
        <div className="error-fallback">
          <p>An error occurred while displaying this component.</p>
        </div>
      );
    }
  };
  
  // PS_DB_51: Construct the windowsUpgradeData array, containing different upgrade statuses along with values and fills.
  const windowsUpgradeData = [
    { name: 'Hardware Refresh', value: data.windows11UpgradePath.hardwareRefresh, fill: '#E84447' },
    { name: 'Needs Review', value: data.windows11UpgradePath.needsReview, fill: '#F6C43A' },
    { name: 'Ready to Upgrade', value: data.windows11UpgradePath.readyToUpgrade, fill: '#4ECA77' },
    { name: 'Yet to Start', value: data.windows11UpgradePath.yetToStart, fill: '#CCCCCC' },
  ];

  // PS_DB_52: Prepare chart data array for displaying the manufacturer compatibility using processed data and COLORS object.
  const chartData = data.manufacturerCompatibility
    .filter(m => m.manufacturer && m.manufacturer.trim() !== '')
    .map(m => ({
      name: m.manufacturer,
      Compatible: m.compatible,
      Incompatible: m.incompatible
    }));

  // PS_DB_53: Orchestrate array hardwareChartData, translating data structure to values compatible with the hardware compatibility bar chart.
  const hardwareChartData = Object.entries(data.hardwareCompatibility)
    .map(([component, values]) => ({
      component,
      Compatible: values.compatible,
      Incompatible: values.incompatible
    }));

  // PS_DB_54: Render conditional  based on isLoading state to display page loader or dashboard content.
  if (isLoading) {
    return (
      <div className="page-loader-parent">
        <div className="page-loader">
          <img
            src="images/Win Remedy page loader.gif"
            alt="page-loader"
            className="page-loader-img"
          />
        </div>
      </div> 
    );
  }
    // PS_DB_54: Device details tab click

  const handleDeviceDetailsClick = () => {
      // PS_DB_148: Navigate to device details page with assessmentID in state
      navigate('/device', { state: { assessmentName: assessmentName,assessmentId:assessmentId?.toString(), organizationName: organizationName,managementType:managementType } })
   
  };

    const closePopup=()=>{
    setpop(!pop)
    }
  // PS_DB_55: Render main container div with 'container-fluid daskboard-bg p-4 pb-0' and 'dashboard' id.
  return (
    <>
 
    <NavHeader/>
    <div className="container-fluid daskboard-bg p-4 pb-0" id="dashboard">
      {/* PS_DB_56: Render a div that is flex and justified between to contain the navigation tabs and the export buttons 'dashboard-header'. */}
      <div className="d-flex justify-content-between" id="dashboard-header">
        {/* PS_DB_57: Render a navigation list to hold tabs 'custom-nav-tabs nav-tabs mb-4' with 'myTab' id. */}
        <ul className="nav custom-nav-tabs nav-tabs mb-4" id="myTab" role="tablist">
          {/* PS_DB_58: Render the 'Dashboard' tab as a list item button and style based on the activeTab state, changing the class to 'active' if needed. */}
          {/* PS_DB_59: Attach an onClick event handler to the 'Dashboard' button that updates the active tab to 'Dashboard'. */}
          <li className="nav-item" role="presentation">
            <button 
              className={`nav-link bg-transparent ms-2 ${activeTab === 'Dashboard' ? 'active' : ''}`}
              onClick={() => setActiveTab('Dashboard')}
              id="Dashboard-tab" 
              data-bs-toggle="tab" 
              data-bs-target="#Dashboard" 
              type="button" 
              role="tab" 
              aria-controls="Dashboard" 
              aria-selected={activeTab === 'Dashboard'}
            >
              Dashboard
            </button>
          </li>
          {/* PS_DB_60: Render the 'Device Details' tab as a list item button and activate it based on the activeTab state. */}
          {/* PS_DB_61: Attach an onClick event handler to the 'Device Details' button that updates the active tab to 'Devicedetails'. */}
          <li className="nav-item" role="presentation">
            <button 
              className={`nav-link bg-transparent ${activeTab === 'Devicedetails' ? 'active' : ''}`}
              onClick={() => handleDeviceDetailsClick()}
              id="Devicedetails-tab" 

              type="button" 
              role="tab" 
              aria-controls="Devicedetails"
              aria-selected={activeTab === 'Devicedetails'}
              
            >
              Device Details
            </button>
          </li>
        </ul>  
        {/* PS_DB_62: Conditionally render an export button and accompanying dropdown menu when the 'Dashboard' tab is active. */}
        {activeTab === 'Dashboard' && (
          <div className="d-flex">
            <div className="download-option-dropdown position-relative">
              {/* PS_DB_63: Render export button with dropdown menu activation that includes options 'Full Report' and 'Device Details'. */}
              <button type="button"  className="primary-btn position-relative font-14 font-medium"
                data-bs-toggle="dropdown" aria-expanded="false">
                Export<span className="custom-btn-border"></span>
                <img src="images/dropdown arrow white.svg" className="dropdown arrow white" alt="dropdown arrow white" />
              </button>
              <ul className="dropdown-menu dropdown-menu-end border-0 dd-position export-dropdown2" aria-labelledby="dropdownMenuLink" data-popper-placement="bottom-end">
                {/* PS_DB_64: Attach onClick event handlers to the dropdown items to call exportFullReport and exportToExcel functions, respectively. */}
                <li 
                  className="font-regular font-13 secondary-text-color-dark border-top-0"
                  onClick={exportFullReport}
                  style={{ cursor: 'pointer' }}
                >
                  Full Report
                </li>    
                <li className="font-regular font-13 secondary-text-color-dark"
                onClick={exportToExcel}
                style={{ cursor: 'pointer' }}
                >
                  Device Details
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      
      {/* PS_DB_65: Render the 'tab-content' div to display either the dashboard's data visuals or device details based on the activeTab state. */}
      <div className="tab-content" id="myTabContent">
        {activeTab === 'Dashboard' && (
          <div className="tab-pane fade show active" id="Dashboard" role="tabpanel" aria-labelledby="Dashboard-tab">
            {/* PS_DB_66: If activeTab is 'Dashboard', render a series of data blocks using a 'map' function to display different device statistics and charts such as total devices and assessment progress. */}
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-3 dashboard-container">
                <div className="dashboard-section d-flex align-items-center justify-content-between">
                  <div>
                    <p className="font-regular font-16 secondary-text-color-dark mb-4 text-nowrap">Total Device in Scope</p>
                    <p className="font-medium font-28 secondary-text-color-dark mb-1" style={{ textAlign: 'left' }}>{data.totalDevice} </p>
                  </div>
                  <img src="images/total-devices.svg" alt="image" />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-3 dashboard-container">
                <div className="dashboard-section d-flex align-items-center justify-content-between">
                  <div>
                    <p className="font-regular font-16 secondary-text-color-dark mb-2 text-nowrap">Assessment Progress</p>
                    <p className="font-medium font-28 secondary-text-color-dark" style={{ textAlign: 'left' }}>
                      <span>{data.assessmentProgress}</span>/<span>{data.totalDevice}</span>
                    </p>
                    <span className="font-medium font-13 color-grey-v1 d-block" style={{ textAlign: 'left' }}>
                      {calculatePercentage(data.assessmentProgress, data.totalDevice)}%
                    </span>
                  </div>
                  <img src="images/assessment-progress.svg" alt="image" />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-3 dashboard-container">
                <div className="dashboard-section d-flex align-items-center justify-content-between">
                  <div>
                    <p className="font-regular font-16 secondary-text-color-dark mb-2 text-nowrap">Device Ready to Upgrade</p>
                    
                    <p className="font-medium font-28 secondary-text-color-dark" style={{ textAlign: 'left' }}>
                      <span>{data.deviceReadyToUpgrade}</span>/<span>{data.totalDevice}</span>
                    </p>
                    <span className="font-medium font-13 color-grey-v1 d-block" style={{ textAlign: 'left' }}>
                      {calculatePercentage(data.deviceReadyToUpgrade, data.totalDevice)}%
                    </span>
                  </div>
                  <img src="images/device-ready.svg" alt="image" />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-3 dashboard-container">
                <div className="dashboard-section d-flex align-items-center justify-content-between">
                  <div>
                  <p className="font-regular font-16 secondary-text-color-dark mb-2 text-nowrap">
  Device with Unsuited Apps
  {data.deviceWithUnsuitedApps > 0 && (
    <button
      type="button"
      className="p-0 border-0 bg-white"
      onClick={() => closePopup()}
    >
      <img src="images/popup-btn.svg" alt="popup" />
    </button>
  )}
</p>
<p
  className="font-medium font-28 secondary-text-color-dark"
  style={{ textAlign: 'left' }}
>
  <span>{data.deviceWithUnsuitedApps}</span>/<span>{data.totalDevice}</span>
</p>
                    <span className="font-medium font-13 color-grey-v1 d-block" style={{ textAlign: 'left' }}>
                      {calculatePercentage(data.deviceWithUnsuitedApps, data.totalDevice)}%
                    </span>
                  </div>
                  <img src="images/unsuited-apps.svg" alt="image" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="dashboard-section circle-db">
                  <h3 className="font-regular font-16 secondary-text-color-dark mb-4" style={{ textAlign: 'left' }}>Windows 11 Upgrade Path</h3>
                  {data.totalDevice > 0 ? (
                    <div className="windows-upgrade d-flex align-items-center justify-content-between">
                      {/* PS_DB_67: Apply map function on windowsUpgradeData array to render pie charts and their related information within the dashboard stats sections. */}
                      <div className="custom-pie-chart position-relative  align-items-center" style={{ margin: '0 auto', marginTop: '-80px', maxWidth: '300px' }}>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={windowsUpgradeData}
                              cx="50%"
                              cy="50%"
                              innerRadius={70}
                              outerRadius={80}
                              startAngle={90}
                              endAngle={-270}
                              dataKey="value"
                              labelLine={false}
                            >
                              {windowsUpgradeData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <text x={'50%'} y={'50%'} textAnchor="middle" dominantBaseline="central" fill="#000" fontSize={20}>
                              Total
                            </text>
                            <text x={'50%'} y={'50%'} dy={20} textAnchor="middle" dominantBaseline="central" fill="#000" fontSize={20}>
                              {data.totalDevice}
                            </text>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div>
                        {windowsUpgradeData.map((entry, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between mb-5">
                            <div className="d-flex align-items-center">
                              <span className="status-indication" style={{ backgroundColor: entry.fill, width: '12px', height: '12px', marginRight: '8px' }}></span>
                              <span className="font-12 font-medium secondary-text-color-dark text-nowrap">{entry.name}</span>
                            </div>
                            <span className="font-12 font-semibold px-3">{entry.value}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="dashboard-section circle-db">
                  <h3 className="font-regular font-16 secondary-text-color-dark mb-4" style={{ textAlign: 'left' }}>Application Compatibility</h3>
                  {data.applicationCompatibility?.totalApplications !== 0 ? (
                    <div className="windows-upgrade d-flex align-items-center justify-content-between">
                      <div className="custom-pie-chart position-relative  align-items-center" style={{ margin: '0 auto', marginTop: '-80px', maxWidth: '300px' }}>
                      <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie
                              data={pieData}
                              cx="50%"
                              cy="50%"
                              innerRadius={70}
                              outerRadius={80}
                              startAngle={90}
                              endAngle={-270}
                              dataKey="value"
                              labelLine={false}
                            >
                              {pieData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.fill} />
                              ))}
                            </Pie>
                            <Tooltip />
                            <text x={'50%'} y={'50%'} textAnchor="middle" dominantBaseline="central" fill="#000" fontSize={20}>
                              Total
                            </text>
                            <text x={'50%'} y={'50%'} dy={20} textAnchor="middle" dominantBaseline="central" fill="#000" fontSize={20}>
                              {data.applicationCompatibility?.totalApplications || 0}
                            </text>
                          </PieChart>
                        </ResponsiveContainer>
                      </div>
                      <div>
                        <div className="d-flex align-items-center justify-content-between mb-5">
                          <div className="d-flex align-items-center">
                          <span className="status-indication status-red me-2"></span>
                            <span className="font-12 font-medium secondary-text-color-dark text-nowrap">Unsupported Apps</span>
                          </div>
                          <span className="font-12 font-semibold px-3">{data.applicationCompatibility?.unsupportedApps || 0}</span>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                          <span className="status-indication status-green me-2"></span>
                            <span className="font-12 font-medium secondary-text-color-dark text-nowrap">Supported Apps</span>
                          </div>
                          <span className="font-12 font-semibold px-3">{data.applicationCompatibility?.supportedApps || 0}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="dashboard-section">
                  <h3 className="font-regular font-16 secondary-text-color-dark mb-4" style={{ textAlign: 'left' }}>Manufacturer Compatibility</h3>
                  {chartData.length > 0 ? (
                    <div>
                      {/* PS_DB_68: Use map function on chartData array to render manufacturer compatibility bar charts with their labels and corresponding color codes based on compatibility. */}
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Bar dataKey="Incompatible" fill={COLORS.incompatible} barSize={50}>
                            <LabelList dataKey="Incompatible" content={<CustomLabel />} />
                            {chartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS.incompatible} />
                            ))}
                          </Bar>
                          <Bar dataKey="Compatible" fill={COLORS.compatible} barSize={50}>
                            <LabelList dataKey="Compatible" content={<CustomLabel />} />
                            {chartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS.compatible} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      <div className="compatibility-legend" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                        <div className="compatibility-item" style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                          <div style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: COLORS.compatible,
                            borderRadius: '2px 0 0 0',
                            marginRight: '8px'
                          }}></div>
                          <span className="font-12 font-medium secondary-text-color-dark">Compatible</span>
                        </div>
                        <div className="compatibility-item" style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: COLORS.incompatible,
                            borderRadius: '2px 0 0 0',
                            marginRight: '8px'
                          }}></div>
                          <span className="font-12 font-medium secondary-text-color-dark">Incompatible</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
              </div>
              
<div className="col-lg-6 mb-3">
  <div className="dashboard-section">
    <h3 className="font-regular font-16 secondary-text-color-dark mb-4" style={{ textAlign: 'left' }}>Hardware Compatibility</h3>
    {hardwareChartData.length > 0 && hardwareChartData.some(item => item.Compatible > 0 || item.Incompatible > 0) ? (
      <div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={hardwareChartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="component" 
              tickFormatter={(value: string) => {
                const componentMap: { [key: string]: string } = {
                  cpu: 'CPU',
                  hdd: 'HDD',
                  tpmStatus: 'TPM Status',
                  ram: 'RAM',
                  secureBoot: 'Secure Boot',
                  cpuCores: 'CPU Cores'
                };
                return value in componentMap ? componentMap[value] : value;
              }}
            />
            <YAxis />
            <Tooltip 
              labelFormatter={(value: string) => {
                const componentMap: { [key: string]: string } = {
                  cpu: 'CPU',
                  hdd: 'HDD',
                  tpmStatus: 'TPM Status',
                  ram: 'RAM',
                  secureBoot: 'Secure Boot',
                  cpuCores: 'CPU Cores'
                };
                return value in componentMap ? componentMap[value] : value;
              }}
            />
            <Bar dataKey="Incompatible" fill={COLORS.incompatible} barSize={50}>
              <LabelList dataKey="Incompatible" content={<CustomLabel />} />
              {hardwareChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS.incompatible} />
              ))}
            </Bar>
            <Bar dataKey="Compatible" fill={COLORS.compatible} barSize={50}>
              <LabelList dataKey="Compatible" content={<CustomLabel />} />
              {hardwareChartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS.compatible} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <div className="compatibility-legend" style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
          <div className="compatibility-item" style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
            <div style={{
              width: '12px',
              height: '12px',
              backgroundColor: COLORS.compatible,
              borderRadius: '2px 0 0 0',
              marginRight: '8px'
            }}></div>
            <span className="font-12 font-medium secondary-text-color-dark">Compatible</span>
          </div>
          <div className="compatibility-item" style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              width: '12px',
              height: '12px',
              backgroundColor: COLORS.incompatible,
              borderRadius: '2px 0 0 0',
              marginRight: '8px'
            }}></div>
            <span className="font-12 font-medium secondary-text-color-dark">Incompatible</span>
          </div>
        </div>
      </div>
    ) : (
      <NoRecordsFound />
    )}
  </div>
</div>
            </div>
            {/* PS_DB_70: Include a footer section within the dashboard display with copyright and version information using standard JSX elements for images and paragraphs. */}
            <div className="custom-footer"> 
              <p className="mb-0">
                <img src="images/copyright.svg" className="me-2 mb-1" alt="copyright" />
                Win Remedy v1.0.0
              </p>
            </div>
          </div>
        )}
      </div>

      {/* PS_DB_71: Render a hidden 'tab-pane' div with table for device details which might be used for exporting pdf , populated using the map function over deviceDetails. */}
      <div className="tab-pane fade show active hidden"  role="tabpanel" aria-labelledby="Devicedetails-tab" id ="hiddendeviceDetails" >
        <div className="table-responsive mt-2 acc-details-table">
          <table className="table">
            <thead>
              <tr>
                <th className="border-0 p-4" />
                <th className="border-0">
                  <div className="d-flex align-items-center gap-3">
                    Username
                  </div>
                </th>
                <th className="border-0">
                  Device Name
                </th>
                <th className="border-0 text-center">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {/* PS_DB_72: Use the map function to iterate over each deviceDetails entry and render table rows with device information such as username, device name, and status, applying conditional styling to the status based on its value. */}
              {deviceDetails.map((device: any, index : any) => (
                <tr key={index} className="accordion-middle cursor-pointer bg-transparent">
                  <td className="text-center">
                    {/* You can add an expand/collapse icon here if needed */}
                  </td>
                  <td>
                    <div className="d-flex align-items-center gap-3">
                      <span>{device.userName}</span>
                    </div>
                  </td>
                  <td>{device.deviceName}</td>
                  <td>
  <div className="d-flex align-items-center justify-content-center">
    <span
      className={`status-badge ${
        device.status.toUpperCase() === "HARDWARE REFRESH"
          ? "hardware-refresh"
          : device.status.toUpperCase() === "READY TO UPGRADE"
          ? "ready"
          : device.status.toUpperCase() === "NEEDS REVIEW"
          ? "needs-review"
          : "yet-to-start"
      } d-flex align-items-center gap-2 text-nowrap font-12 font-medium justify-content-center`}
    >
      <span className="status-legend" />
      {device.status}
    </span>
  </div>
</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {pop?<UnSupportAppsComponent assesmentId={assessmentId} closePopup={closePopup}></UnSupportAppsComponent>:null}

    </div>
    </>
  );
}

export default DashboardComponent;