import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface NavHeaderProps {
  onLogoClick?: () => void;
}

const NavHeader: React.FC<NavHeaderProps> = ({ onLogoClick }) => {
  const [logo, setLogo] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [timeoutPopup, setTimeoutPopup] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [headerLogo, setHeaderLogo] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const TIMEOUT_DURATION = 1800000; // 1800000; // 30 minutes in milliseconds
  const username = localStorage.getItem("userName") || "";
  const email = localStorage.getItem("emailId") || "";
  const assessmentName =
    localStorage.getItem("assessmentName") || "Assessment Name";
  const organizationName2 =
    localStorage.getItem("organizationName") || "Organization Name";
  // Clear sessionTimedOut flag on component mount
  const saveLogToLocalStorage = (logMessage: string) => {
    const logs = JSON.parse(localStorage.getItem("logs") ?? "[]"); // Get existing logs or initialize as an empty array
    logs.push(logMessage); // Add new log message
    localStorage.setItem("logs", JSON.stringify(logs)); // Save updated logs array back to local storage
  };

  const startTimer = useCallback(() => {
    const startTime = new Date().toLocaleString();
    return setTimeout(() => {
      const timeoutTime = new Date().toLocaleString();
      setTimeoutPopup(true);
      localStorage.setItem("sessionTimedOut", "true");
    }, TIMEOUT_DURATION);
  }, []);
  //PS_NH_06, PS_NH_14 - PS_NH_33

  useEffect(() => {
    let timeoutId = startTimer();

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        saveLogToLocalStorage("Timer reset due to user activity");
      }
      timeoutId = startTimer();
    };

    const handleUserActivity = () => {
      if (!timeoutPopup) {
        resetTimer();
        saveLogToLocalStorage("handleUserActivity");
      }
    };

    const checkTimeout = () => {
      const sessionTimedOut = localStorage.getItem("sessionTimedOut");
      if (sessionTimedOut === "true" && !timeoutPopup) {
        saveLogToLocalStorage("Session timed out detected on check");
        setTimeoutPopup(true);
      }
    };

    const handleBeforeUnload = () => {
      if (timeoutPopup) {
        saveLogToLocalStorage("Page is being refreshed after timeout");
        localStorage.setItem("refreshedAfterTimeout", "true");
        saveLogToLocalStorage("refreshedAfterTimeout true");
      }
    };

    const userActivityEvents = ["mousemove", "keydown", "click", "scroll"];

    userActivityEvents.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );
    window.addEventListener("beforeunload", handleBeforeUnload);
    createProfileName();
    resetTimer(); // Initialize the timer
    const intervalId = setInterval(checkTimeout, 1000);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      clearInterval(intervalId);

      userActivityEvents.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [startTimer, timeoutPopup]);

  //PS_NH_34 - PS_NH_37
  const createProfileName = () => {
    const nameParts = username.split(" ");
    saveLogToLocalStorage("createProfileName");
    let logoName = "";
    if (nameParts.length >= 2) {
      logoName =
        nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0);
    } else if (nameParts.length === 1) {
      logoName = nameParts[0].substring(0, 2);
    }
    setLogo(logoName.toUpperCase());
  };

  //PS_NH_38, PS_NH_55 - PS_NH_63
  const handleLogout = () => {
    try {
      const emailId = localStorage.getItem("emailId") || "";
      const assessmentId = localStorage.getItem("assessmentId") || "";
      saveLogToLocalStorage("handleLogout: /userLogin");
      const redirectUrl = `/userLogin${
        emailId && assessmentId
          ? `?emailId=${emailId}&assessmentId=${assessmentId}`
          : ""
      }`;

      sessionStorage.clear();
      localStorage.clear();
      setTimeoutPopup(false);
      setShowLogoutConfirmation(false);
      if (emailId && assessmentId && location.pathname === "/download") {
        saveLogToLocalStorage("handleLogout: /download");
        navigate(redirectUrl);
      } else {
        saveLogToLocalStorage("handleLogout: /");
        navigate("/");
      }
    } catch (error) {
      saveLogToLocalStorage("error: handleLogout: /");
      console.error("Logout error:", error);
    }
  };
  //PS_NH_65 - PS_NH_67

  // useEffect(() => {

  // }, [timeoutPopup]);
  // PS_NH_68 - PS_NH_71

  useEffect(() => {
    const emailId = localStorage.getItem("emailId") || "";
    const assessmentId = localStorage.getItem("assessmentId") || "";
    saveLogToLocalStorage("useEffect: emailId");
    if (emailId != null && assessmentId != null) {
      const redirectUrl = `/userLogin${
        emailId && assessmentId
          ? `?emailId=${emailId}&assessmentId=${assessmentId}`
          : ""
      }`;

      const refreshedAfterTimeout = localStorage.getItem(
        "refreshedAfterTimeout"
      );
      saveLogToLocalStorage("useEffect: refreshedAfterTimeout");
      if (refreshedAfterTimeout === "true") {
        if (localStorage.getItem("OTPGenerated") == null) {
          saveLogToLocalStorage(
            "useEffect: Redirecting after page refresh due to timeout"
          );
          localStorage.removeItem("refreshedAfterTimeout");
          if (emailId && assessmentId && location.pathname === "/download") {
            saveLogToLocalStorage("useEffect: /download");
            navigate(redirectUrl);
          }
        } else if (localStorage.getItem("sessionTimedOut") == "true") {
          if (
            location.pathname === "/dashboard" ||
            location.pathname === "/device" ||
            location.pathname === "/organization"
          ) {
            saveLogToLocalStorage("useEffect: /");
            navigate("/");
          }
        }
      }
    } else {
      if (localStorage.getItem("sessionTimedOut") == null) {
        if (
          location.pathname === "/dashboard" ||
          location.pathname === "/device" ||
          location.pathname === "/organization"
        ) {
          saveLogToLocalStorage("useEffect: /device");
          setHeaderLogo(assessmentName);
          setOrganizationName(organizationName2);
        }
      } else {
        saveLogToLocalStorage("useEffect: else");
        setHeaderLogo("");
        setOrganizationName("");
      }
    }
  }, [navigate, location]);
  //PS_NH_39, PS_NH_46, PS_NH_47

  const toggleDropdown = () => {
    saveLogToLocalStorage("toggleDropdown");
    setShowDropdown(!showDropdown);
  };
  //PS_NH_75 - PS_NH_76

  // useEffect(() => {

  // }, [location]);

  if (location.pathname === "/organization") {
    return (
      <>
        <nav className="navbar navbar-expand-lg custom-nav">
          <div className="container-fluid custom-container">
            <div
              className="navbar-brand d-flex logo-with-text align-items-center cursor-pointer"
              onClick={onLogoClick}
            >
              <img src="images/logo.svg" alt="logo" className="header-logo" />
              <h3 className="ms-2 primary-text-color font-medium font-18">
                Win Remedy
              </h3>
            </div>
            <div className="profile-section">
              <button
                type="button"
                onClick={toggleDropdown}
                className="btn border-0 shadow-none background-white p-0"
              >
                <p className="profile-btn profile-text-color profile-bg font-semibold font-16">
                  {logo}
                </p>
              </button>
              {showDropdown && (
                <div className="dropdown-menu dropdown-menu-end text-center border-0 logout-container show">
                  <div className="logout-section">
                    <p className="profile-btn profile-text-color profile-bg font-semibold font-28 me-3">
                      {logo}
                    </p>
                    <div className="me-1">
                      <p
                        className="font-16 font-medium m-0 color-black text-start text-truncate"
                        style={{ maxWidth: "150px" }}
                        title={username}
                      >
                        {username}
                      </p>
                      <span
                        className="font-12 font-medium secondary-text-color mt-1 mb-0 text-decoration-none grey-text-color-light text-truncate d-inline-block"
                        style={{ maxWidth: "150px" }}
                        title={email}
                      >
                        {email}
                      </span>
                    </div>
                    <img
                      src="/images/logout-icon.svg"
                      alt="logout-icon"
                      className="logout-icon ms-4 cursor-pointer"
                      onClick={() => setShowLogoutConfirmation(true)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </nav>
        {(showLogoutConfirmation || timeoutPopup) && (
          <div
            className="modal"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
              <div className="modal-content custom-popup border-0">
                <div className="modal-body p-5 pb-4 text-center">
                  <img
                    src="/images/danger-icon.svg"
                    alt="danger-icon"
                    className="danger-icon"
                  />
                  {timeoutPopup ? (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">
                        Your Session has been timed out due to inactivity.
                        Please login again into the application.
                      </p>
                      <button
                        className="primary-btn popup-btn mt-3 mb-3"
                        type="button"
                        onClick={handleLogout}
                      >
                        Ok
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">
                        Are you sure you want to Logout ?
                      </p>
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 pt-1">
                        <button
                          className="outline-btn popup-btn me-2"
                          type="button"
                          onClick={() => setShowLogoutConfirmation(false)}
                        >
                          No
                        </button>
                        <button
                          className="primary-btn popup-btn ms-2"
                          type="button"
                          onClick={handleLogout}
                        >
                          Yes
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  if (location.pathname === "/dashboard" || location.pathname === "/device") {
    return (
      <>
        <nav className="navbar navbar-expand-lg custom-nav">
          <div className="container-fluid custom-container">
            <div className="d-flex align-items-center">
              <a className="navbar-brand" href="#">
                <span className="d-flex logo-with-text">
                  <img
                    src="images/back-arrow-icon.svg"
                    alt="back-arrow-icon"
                    className="header-back-arrow-icon"
                    onClick={() => navigate("/organization")}
                  />
                </span>
              </a>
              <h3 className="ms-3 black-color font-regular font-20">
                {assessmentName}
              </h3>
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="organization-section">
                <p className="font-regular font-12 grey-text-color-dark">
                  Organization Name:{" "}
                  <span className="font-medium primary-text-color">
                    {organizationName2}
                  </span>
                </p>
              </div>
              <div className="profile-section">
                <button
                  type="button"
                  onClick={toggleDropdown}
                  className="btn border-0 shadow-none background-white p-0"
                >
                  <p className="profile-btn profile-text-color profile-bg font-semibold font-16">
                    {logo}
                  </p>
                </button>
                {showDropdown && (
                  <div className="dropdown-menu dropdown-menu-end text-center border-0 logout-container show">
                    <div className="logout-section">
                      <p className="profile-btn profile-text-color profile-bg font-semibold font-28 me-3">
                        {logo}
                      </p>
                      <div className="me-1">
                        <p
                          className="font-16 font-medium m-0 color-black text-start text-truncate"
                          style={{ maxWidth: "150px" }}
                          title={username}
                        >
                          {username}
                        </p>
                        <span
                          className="font-12 font-medium secondary-text-color mt-1 mb-0 text-decoration-none grey-text-color-light text-truncate d-inline-block"
                          style={{ maxWidth: "150px" }}
                          title={email}
                        >
                          {email}
                        </span>
                      </div>
                      <img
                        src="/images/logout-icon.svg"
                        alt="logout-icon"
                        className="logout-icon ms-4 cursor-pointer"
                        onClick={() => setShowLogoutConfirmation(true)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
        {(showLogoutConfirmation || timeoutPopup) && (
          <div
            className="modal"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
              <div className="modal-content custom-popup border-0">
                <div className="modal-body p-5 pb-4 text-center">
                  <img
                    src="/images/danger-icon.svg"
                    alt="danger-icon"
                    className="danger-icon"
                  />
                  {timeoutPopup ? (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">
                        Your Session has been timed out due to inactivity.
                        Please login again into the application.
                      </p>
                      <button
                        className="primary-btn popup-btn mt-3 mb-3"
                        type="button"
                        onClick={handleLogout}
                      >
                        Ok
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">
                        Are you sure you want to Logout?
                      </p>
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 pt-1">
                        <button
                          className="outline-btn popup-btn me-2"
                          type="button"
                          onClick={() => setShowLogoutConfirmation(false)}
                        >
                          No
                        </button>
                        <button
                          className="primary-btn popup-btn ms-2"
                          type="button"
                          onClick={handleLogout}
                        >
                          Yes
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  if (location.pathname === "/download") {
    return (
      <>
        <nav className="navbar navbar-expand-lg custom-nav">
          <div className="container-fluid custom-container">
            <a
              className="navbar-brand d-flex logo-with-text align-items-center"
              href="#"
            >
              <img src="images/logo.svg" alt="logo" className="header-logo" />
              <h3 className="ms-2 primary-text-color font-medium font-18">
                Win Remedy
              </h3>
            </a>
          </div>
        </nav>
        {(showLogoutConfirmation || timeoutPopup) && (
          <div
            className="modal"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
              <div className="modal-content custom-popup border-0">
                <div className="modal-body p-5 pb-4 text-center">
                  <img
                    src="/images/danger-icon.svg"
                    alt="danger-icon"
                    className="danger-icon"
                  />
                  {timeoutPopup ? (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">
                        Your Session has been timed out due to inactivity.
                        Please login again into the application.
                      </p>
                      <button
                        className="primary-btn popup-btn mt-3 mb-3"
                        type="button"
                        onClick={handleLogout}
                      >
                        Ok
                      </button>
                    </>
                  ) : (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">
                        Are you sure you want to Logout?
                      </p>
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 pt-1">
                        <button
                          className="outline-btn popup-btn me-2"
                          type="button"
                          onClick={() => setShowLogoutConfirmation(false)}
                        >
                          No
                        </button>
                        <button
                          className="primary-btn popup-btn ms-2"
                          type="button"
                          onClick={handleLogout}
                        >
                          Yes
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <header className="flex justify-between items-center p-4 bg-gray-100"></header>
  );
};

export default NavHeader;
