
// UrlDownload.tsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchUrlTypes, downloadUrlType, postErrorLog } from '../Services/api';
import { AxiosResponse } from 'axios';
import NavHeader from './NavHeader';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Loader from './Loader';
interface UrlType {
  url_id: number;
  download_url: string;
}

interface DownloadRequest {
  type: string;
  bitConfig: number;
  userName: string;
  assessmentID: string;
  mailId : string
}
const UrlDownload: React.FC = () => {
  // Initialize state variables
  const [urlTypes, setUrlTypes] = useState<UrlType[]>([]);
  const [selectedUrlType, setSelectedUrlType] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assessmentId, setAssessmentId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const location = useLocation();
  const [useName, setUserName] = useState<string>('')
 //PS_URL_03
  useEffect(() => {
    // Fetch query parameters or state from location
    if (location.state) {
      if (typeof location.state === 'string') {
        // This is the case from OrganizationGrid.tsx
        setAssessmentId(location.state);
        setEmail(''); // Set email to empty string
      } else {
        // This is the case from userLogin.tsx
        const { emailId, assessmentId } = location.state as { emailId: string; assessmentId: string };
        setEmail(emailId || '');
        setAssessmentId(assessmentId);
      }
    } else {
      // Handle the case where no state is passed
      setEmail('');
      setAssessmentId('');
      // You might want to redirect or show an error message here
    }
    // Trigger fetchUrlTypesData on component mount
    fetchUrlTypesData();
  }, [location]);

  // Function to fetch URL Types
  //PS_URL_04 - PS_URL_12
  async function fetchUrlTypesData() {
    setIsLoading(true);
    try {
      const response:AxiosResponse = await fetchUrlTypes();
      if (response && response.data && Array.isArray(response.data) && response.data.length > 0) {
        setUrlTypes(response.data);
      } else {
        let errorData:object = {
          errorFunction: "fetchUrlTypesData",
          errorMessage: "No data received from API, using default values",
          errorFileName: "UrlDownload.tsx"
      }
      
    // PS_Otp_11 & PS_Otp_12
    await postErrorLog(errorData);
      }
    }  catch (error:any) {
        let errorData:object = {
            errorFunction: "fetchUrlTypesData",
            errorMessage: error.message,
            FileName: "UrlDownload.tsx"
        }
        
      // PS_Otp_11 & PS_Otp_12
      await postErrorLog(errorData);
      // await postErrorLog(errorData); // make sure this function is defined somewhere
      setErrorMessage('An error occurred while fetching URL types.');
    } finally {
      setIsLoading(false);
    }
  }
  // Function to handle download
 
//PS_URL_17 - PS_URL_30
  async function handleDownload(urlType: UrlType): Promise<void> {
    setIsLoading(true);
    try {
      const match = urlType.download_url.match(/win_remedy\.(\w+)\((\d+) bit\)/);
      if (!match) {
        throw new Error('Invalid download URL format');
      }
      const [, fileExtension, config] = match;
  
      let type: string;
      if (fileExtension.toLowerCase() === 'exe') {
        type = 'exe';
      } else if (fileExtension.toLowerCase() === 'msi') {
        type = 'msi';
      } else {
        throw new Error('Unsupported file type');
      }
  
      const downloadRequest: DownloadRequest = {
        type,
        bitConfig: parseInt(config),
        userName : useName,
        mailId: email, // Assuming email is defined in the component state
        assessmentID: assessmentId // Assuming assessmentId is defined in the component state
      };
  
  
      const response: AxiosResponse<ArrayBuffer> = await downloadUrlType(downloadRequest);
  
      const blob = new Blob([response.data], { type: 'application/zip' });
  
      try {
        const zip = await JSZip.loadAsync(blob);
  
        const expectedFiles = [
          { name: 'FinalConsoleApp.dll', expectedSize: 1024 }, // 1 KB
          { name: 'FinalConsoleApp.exe', expectedSize: 65685504 }, // 64,146 KB
          { name: 'FinalConsoleApp.pdb', expectedSize: 14336 }, // 14 KB
          { name: `win_remedy.${type}`, expectedSize: null } // Size unknown, just check presence
        ];
  
        const warnings: string[] = [];
        const foundFiles: string[] = [];
  
        zip.forEach((relativePath, zipEntry) => {
          if (!zipEntry.dir) {
            foundFiles.push(relativePath.toLowerCase());
            zipEntry.async('blob').then((fileBlob) => {
              
              const expectedFile = expectedFiles.find(f => f.name.toLowerCase() === relativePath.toLowerCase());
              if (expectedFile) {
                if (expectedFile.expectedSize && fileBlob.size !== expectedFile.expectedSize) {
                  warnings.push(`${relativePath} size mismatch. Expected: ${expectedFile.expectedSize} bytes, Actual: ${fileBlob.size} bytes`);
                }
              } else {
                warnings.push(`Unexpected file in zip: ${relativePath}`);
              }
            });
          }
        });
  
        const missingFiles = expectedFiles.filter(file => !foundFiles.includes(file.name.toLowerCase()));
        missingFiles.forEach(file => {
          warnings.push(`Missing expected file: ${file.name}`);
        });
  
        if (warnings.length > 0) {
          console.warn('Warnings:', warnings);
          // You might want to show these warnings to the user
          setErrorMessage(`Warnings found in zip contents: ${warnings.join(', ')}`);
        }
  
      } catch (zipError) {
        console.error('Error inspecting zip contents:', zipError);
        setErrorMessage('Error inspecting zip contents. The file might be corrupted.');
      }
  
      // Attempt to save the file
      try {
        saveAs(blob, `WinRemedy_${type}_${config}bit.zip`);
      } catch (saveError) {
        console.error('Error saving file:', saveError);
        // Fallback to manual download if saveAs fails
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `WinRemedy_${type}_${config}bit.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
  
    } catch (error: any) {
      console.error('Download error:', error);
      let errorData:object = {
        errorFunction: "fetchUrlTypesData",
        errorMessage: error.message,
        FileName: "UrlDownload.tsx"
    }
    
  // PS_Otp_11 & PS_Otp_12
  await postErrorLog(errorData);
    } finally {
      setIsLoading(false);
    }
  }
  
  //PS_URL_31
  const formatUrlTypeDisplay = (urlType: UrlType) => {
    return urlType.download_url;
  };
  return (
    <>
    <NavHeader/>
    <Loader isLoading={isLoading} />
      <div className="container-fluid custom-container">
        <div className="d-flex align-items-center justify-content-center download-section">
          <img src="images/logo.svg" className="logo" alt="logo" />
          <p className="download-title font-60 font-light secondary-text-color-dark">
            Download <span className="font-regular">Win Remedy</span>
          </p>
          <p className="font-24 font-light secondary-text-color-dark mt-3 mb-5">
            Available in .msi & .exe for 64-bit and 32-bit.
          </p>
          
          <div className="download-option-dropdown position-relative">
          <button 
  type="button" 
  className="primary-btn position-relative font-14"
  onClick={() => setShowDropdown(!showDropdown)}
>
  {selectedUrlType || 'Download Now'}
  <span className="custom-btn-border"></span>
  <img src="images/dropdown arrow white.svg" className="dropdown arrow white" alt="dropdown arrow white" />
</button>

           {showDropdown && (
  <ul className="dropdown-menu dropdown-menu-end border-0 dd-position show">
    {urlTypes.map((urlType) => (
      <li key={urlType.url_id} onClick={() => {
        setSelectedUrlType(urlType.download_url);
        setErrorMessage('');
        setShowDropdown(false);
        handleDownload(urlType);
      }}>
        {formatUrlTypeDisplay(urlType)}
      </li>
    ))}
  </ul>
)}
          </div>
          <div className="mt-4">
            <img src="images/info icon.svg" className="info-icon" alt="info icon" />
            <span className="font-12 font-regular grey-text-color-light ms-2">
              Want to know how to use Win Remedy and it's features? <a href="#">User Guide</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default UrlDownload;