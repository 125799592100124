
// PS_CL_01 -PS_CL_08 

import axios from 'axios';
import Axios, { AxiosResponse, Method, ResponseType } from 'axios';
// PS_DB_120: Import API_BASE_URL from '../constants' to get the base path for constructing API endpoint URLs.
import { API_BASE_URL } from '../Constant';


export async function client(endpoint: string, body: any = {}, requestType: Method, responseType: ResponseType = 'json'): Promise<AxiosResponse<any>> {
  try {
    let fullURL = API_BASE_URL + `${endpoint}`;

    let headers: any = {
      "Authorization": localStorage.getItem("token"),
    };

    // Set the correct Content-Type based on the body type
    if (body instanceof FormData) {
      headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
    }

    let requestConfig: any = {
      method: requestType,
      url: fullURL,
      headers: headers,
      responseType: responseType,
    };

    // Handle the body based on its type
    if (body instanceof FormData) {
      requestConfig.data = body;
    } else if (Object.keys(body).length > 0) {
      requestConfig.data = JSON.stringify(body);
    }

    const response = await Axios(requestConfig);
    return response;

  } catch (error) {
    console.error("An error occurred while making the request:", error);
    throw error;
  }
}

export default client;

export const DeviceClient = async (config:object) => {
  try {
      
      const response = await axios(config)
      return response.data;
  }
  catch (error:any) {
      console.error(error.message, "error in client ");
      // window.location.href = '/error'
  }
}

 
// PS_DB_121: Define the client async function that takes an endpoint string, an optional body object, and an optional method string defaulting to 'POST'.
export const Dashboardclient = async (endpoint: string, body: any = null, method: string = 'POST') => {
  try {
    // PS_DB_122: Use the URL constructor to properly combine API_BASE_URL and the endpoint
    const url = new URL(endpoint, API_BASE_URL).toString();
 
    // PS_DB_123: Set up the requestConfig object to include the HTTP method, URL, data payload as the body, and 'Content-Type' header for a JSON request.
    let requestConfig = {
      method: method,
      url: url,
      data: body, // The body is included in the data property for axios
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem("token"),
      }
    };
 
    // PS_DB_126: Make an asynchronous request using axios with the provided requestConfig object and wait for the response.
    const response = await axios(requestConfig);
    // PS_DB_128: Return the obtained response back to the calling function for further processing.
    return response;
 
  } catch (error: any) {
    // PS_DB_130: Re-throw the caught error to allow the calling function to handle the exception as appropriate.
    throw error;
  }
};
 

