// Filename: DeviceDetailComponent.tsx
import { event } from "jquery";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";
import NavHeader from './NavHeader';
import Loader from './Loader';
import {
  getDeviceData,
  getUnsupportedApps,
  getStatusData,
  resendMailService,
  postErrorlogs
} from "../Services/api";
// PS_DD_01: Initialize component state with default values

import {
  ManufacturerItem,
  StatusItem,
  DeviceDetail,
  ErrorDetails
} from "../Interface/AssessmentDetailInterface";
export const DeviceDetailComponent = () => {
  //PS_DD_01 Initialize component state
  const emptyDeviceDetail: DeviceDetail[] = [
    {
      userName: "",
      deviceName: "",
      status: "",
      manufacturer: "",
      model: "",
      systemType: "",
      managementType: "",
      hardwareCompatibility: {
        status: "",
        cpuProcessor: "",
        cpuProcessor_status: false,
        cpuCore: "",
        cpuCore_Status: "",
        hdd: "",
        hdd_status: false,
        tpmStatus: false,
        memory: "",
        memory_status: "",
        secureBoot: false,
      },
      softwareCompatibility: {
        status: "",
        operatingSystem: "",
        numberOfInstalledApps: 0,
        numberOfSupportedApps: 0,
        numberOfUnsupportedApps: 0,
      },
    },
  ];

  const [deviceData, setDeviceData] = useState<DeviceDetail[]>();
  const [recordCount, setRecordCount] = useState<number>(10);
  const [searchField, setSearchField] = useState<string>("");
  const [manufacturer, setManufacturer] = useState("");
  const [status, setStatus] = useState("");
  const [sort, setSort] = useState("asc");
  const [deviceSort, setdeviceSort] = useState("asc");
  const [statusSort, setstatusSort] = useState("asc");
  const [sortColumn, setSortColumn] = useState("device_name");
  const [resendMailCount, setResendMailCount] = useState<number>(0);
  const [advFilterPopup, setAdvFilterPopup] = useState<boolean>(false);
  const [totalcount, settotalcount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loadmore, setLoadmore] = useState<number>(10);
  const [retriveDataLength, setretriveDataLength] = useState<number>(0);
  const [manufacturersDropDown, setManufacturersDropDown] = useState<
    ManufacturerItem[]
  >([]);
  const [statusDropdown, setstatusDropdown] = useState<StatusItem[]>([]);
  //PS_DD_47 Initialize useNavigate as nav
  const nav = useNavigate();
  //PS_DD_48 initialize uselocation
  const location = useLocation();
  //PS_DD_48 Extract assessmentId and username from useLocation
const { assessmentName, assessmentId, organizationName } = location.state || {};
const managementType = (location.state?.managementType === "UnManaged") ? "Unmanaged" : location.state?.managementType;
localStorage.setItem('organizationName', organizationName); 
localStorage.setItem('assessmentName', assessmentName); 


  // PS_DD_20: Reset status and manufacturer state variables on clear button click
  useEffect(() => {
    if (status === "" && manufacturer === "") {
      pageLoad();
    }
  }, [status, manufacturer]);
  
  // Then modify clearButtonClick like this:
  const clearButtonClick = () => {
    setStatus("");
    setManufacturer("");
    setAdvFilterPopup(false);
  };

  // PS_DD_02: Set up useEffect hook with dependencies [sort, loadmore]
  useEffect(() => {
    pageLoad();
    statusLoad();
  }, [sort, loadmore]);

  // PS_DD_03: Define asynchronous method pageLoad() to fetch device data
  const pageLoad = async () => {
    // PS_DD_04: Create request parameters object using current state values
    const requestParams = {
      assessmentId: parseInt(assessmentId),
      loadMore: loadmore,
      search: searchField,
      sort: {
        column: sortColumn,
        order: sort,
      },
      filter: {
        manufacturer: manufacturer,
        Status: status,
      },
    };

    // PS_DD_05: Create PageLoadBO object using request parameters
    const PageLoadBO = { ...requestParams };

    // PS_DD_06, 07: Prepare API request body/headers and make API call
    try {
      setLoading(true)
      let response = await getDeviceData(PageLoadBO);
      // PS_DD_08: Process API response
      setLoading(false)
      const deviceDataObj = response.data.deviceDetails.map((device: any) => ({
        ...device,
        mailCheckbox: false,
      }));

      // PS_DD_09: Update component state with new device data
      setDeviceData(deviceDataObj);
      settotalcount(response.data.totalCount);
      setretriveDataLength(deviceDataObj.length);
      setResendMailCount(0);
    } catch (error:any) {
      // PS_DD_09: Update component state with new device data
      setDeviceData(emptyDeviceDetail);
      settotalcount(0);
      setretriveDataLength(0);
      handleError(error.message, "pageLoad");
      setLoading(false)
       /**CR***errorlog method in backend */
    }
  };
  //PS_DD_78 to load the status
  const statusLoad = async () => {
    // PS_DD_79: Create PageLoadBO object using request parameters
    let statusBO = {
      assessmentId: assessmentId?.toString(),
    };

    // PS_DD_80: Prepare API request body/headers and make API call
    try {
      let response = await getStatusData(statusBO);
      //PS_DD_81 map status dropdown and manufacturer dropdown
      let locStatusdropdown = response.statusOptions.map(
        (manufacturer: any) => ({
          key: manufacturer.status_description,
          value: manufacturer.status_description,
        })
      );

      let locManufacturerdropdown = response.manufacturerOptions.map(
        (manufacturer: any) => ({
          key: manufacturer.device_manufacturer,
          value: manufacturer.device_manufacturer,
        })
      );
      //PS_DD_82 update state varaible
      setManufacturersDropDown(locManufacturerdropdown);
      setstatusDropdown(locStatusdropdown);
    } catch (error:any) {
      handleError(error.message, "statusLoad");
    }
  };

  // PS_DD_10: Handle field change events
  const onFieldChange = (fieldName: any, event: any, sortColumn: string) => {
    
    switch (fieldName) {
      case "sort":
        // PS_DD_11: Toggle between 'asc' and 'desc'
        setSort(sort === "asc" ? "desc" : "asc");
        setSortColumn(sortColumn);
        if (sortColumn == "device_name") {
          setdeviceSort(sort === "asc" ? "desc" : "asc");
        } else {
          setstatusSort(sort === "asc" ? "desc" : "asc");
        }
        // Assuming sortColumn needs to be updated
        break;
      case "recordCount":
        // PS_DD_12: Update state with new recordCount value
        setRecordCount(parseInt(event.target.value));
        setLoadmore(parseInt(event.target.value));
        break;
      case "manufacturer":
        // PS_DD_13: Update state with new manufacturer value
        setManufacturer(event.target.value);
        break;
      case "status":
        // PS_DD_14: Update state with new status value
        setStatus(event.target.value);
        break;
      case "SearchField":
        // PS_DD_15: Update state with new SearchField value
        setSearchField(event.target.value);
        break;

      default:
        break;
    }
  };
  //  PS_DD_16: Increment/decrement resendMail based on checkbox state
  const sendmailButtonclick = (event: any, index: number) => {
    setResendMailCount((prevCount) => {
      let increment = 0;
      if (event.target.checked) {
        increment = prevCount + 1;
      } else {
        increment = prevCount - 1;
      }
      return increment;
    });
    setDeviceData((prevData: any) =>
      prevData.map((item: any, i: number) =>
        i === index ? { ...item, mailCheckbox: event.target.checked } : item
      )
    );
  };

  // PS_DD_17: Toggle advFilterPopup state
  const advancedFileterPopup = () => {
    setAdvFilterPopup(!advFilterPopup);
  };

  // PS_DD_18: Update loadmore state for loadmore button click
  const loadmoreButtonClick = () => { /***CR camel case */
    setLoadmore((prevLoadmore) => prevLoadmore + recordCount );
  };

  // PS_DD_19: Trigger search on icon/button click
  const searchOnclick = () => {
    pageLoad();
    setAdvFilterPopup(false);
  };

  // PS_DD_22-SQ_EX_47: Loop through deviceData and bind device details to view
  const bindDeviceDetail = () => {
    let displayCount = 0;
    if (deviceData != null) {
      if (totalcount != 0) {
        return deviceData.map((val: any, index: number) => {
          //PS_DD_40: Assign constructed HTML to view element - Assuming some container exists
          return (
            <>
              <tr className="accordion-middle cursor-pointer bg-transparent ">
                <td className="text-center">
                  {/*{val.status.toUpperCase() != "YET TO START" ? (*/}
                    <span
                      className={`table-accordion-arrow header-arrow collapsed ${val.status.toUpperCase() == "YET TO START" ? "device-accordian-opac" :""} `}
                      id={`detailHead-${index}`}
                      aria-expanded="true"
                      onClick={() => handleAccordionClick(index)}
                      
                    >
                      <img
                        className="acc-table-arrow cursor-pointer"
                        src="images/table-acc-arrow.svg"
                        alt="notifications-arrow"
                      />
                    </span>
                {/*}  ) : null}*/}
                </td>
                <td> 
                  <div className="d-flex align-items-center gap-3">
                    {/*{manage_type!="AD"?*/}{" "}
                    
                      <input
                      title="resend"
                        className="form-check-input"
                        name="resendmail"
                        type="checkbox"
                        onChange={(event) => sendmailButtonclick(event, index)}
                        checked={val.mailCheckbox}
                        disabled={val.status.toUpperCase() == "YET TO START" && managementType.toUpperCase()== "UNMANAGED" ?false:true}
                      />
                    
                    {/*}:null}*/}
                    <span>{val.userName}</span>
                  </div>
                </td>
                <td>{val.deviceName}</td>
<td>
  <div className="d-flex align-items-center justify-content-center">
    <span
      className={`status-badge ${
        val.status.toUpperCase() === "HARDWARE REFRESH"
          ? "hardware-refresh"
          : val.status.toUpperCase() === "READY TO UPGRADE"
          ? "ready"
          : val.status.toUpperCase() === "NEEDS REVIEW"
          ? "needs-review"
          : "yet-to-start"
      } d-flex align-items-center gap-2 text-nowrap font-12 font-medium justify-content-center`}
    >
      <span className="status-legend" />
      {val.status}
    </span>
  </div>
</td>
              </tr>
              {val.status.toUpperCase() != "YET TO START" ? (
                <tr
                  className="collapse accor content-row "
                  id={`detail-${index}`}
                >
                  <td
                    colSpan={4}
                    className="custom-inner-table-section ps-5 pt-0 pb-0"
                  >
                    <div className="row inner-table-details">
                      <div className="col-md-4 p-0">
                        <p className="font-16 font-medium table-title-color">
                          Device Details
                        </p>
                        <table className="inner-details-table mt-4">
                          <thead />
                          <tbody>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                Manufacturer:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.manufacturer}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                Model:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.model}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                System Type:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.systemType}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                Management Type:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                              {val.deviceDetails.managementType === "UnManaged" 
  ? "Unmanaged" 
  : val.deviceDetails.managementType}                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4 p-0">
                        <div className="d-flex align-items-center gap-3">
                          <p className="font-16 font-medium table-title-color">
                            Hardware Compatibility
                          </p>
                          <span
                            className={`status-badge  ${
                              val.deviceDetails.hardwareDetailsDto.status ==
                              `Passed`
                                ? `ready`
                                : `hardware-refresh`
                            }   d-flex align-items-center gap-2 text-nowrap font-12 font-medium w-auto`}
                          >
                            <span className="status-legend" />
                            {val.deviceDetails.hardwareDetailsDto.status}
                          </span>
                        </div>
                        <table className="inner-details-table mt-4">
                          <thead />
                          <tbody>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                CPU Processor:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {
                                  val.deviceDetails.hardwareDetailsDto
                                    .cpuProcessor
                                }
                              </td>
                              <td>
                                {val.deviceDetails.hardwareDetailsDto
                                  .cpuProcessStatus ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                CPU core:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.hardwareDetailsDto.cpuCore}
                              </td>
                              <td>
                                {val.deviceDetails.hardwareDetailsDto
                                  .cpuCoreStatus ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                HDD:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.hardwareDetailsDto.hdd}{" "}
                               
                              </td>
                              <td>
                                {val.deviceDetails.hardwareDetailsDto
                                  .hddStatus ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                TPM Status:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.hardwareDetailsDto.tpmStatus
                                  ? `True`
                                  : `False`}
                              </td>
                              <td>
                                {val.deviceDetails.hardwareDetailsDto
                                  .tpmStatus ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                Memory:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.hardwareDetailsDto.memory}
                              </td>
                              <td>
                                {val.deviceDetails.hardwareDetailsDto
                                  .memoryStatus ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                Secure Boot:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {val.deviceDetails.hardwareDetailsDto.secureBoot
                                  ? `True`
                                  : `False`}
                              </td>
                              <td>
                                {val.deviceDetails.hardwareDetailsDto
                                  .secureBoot ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4 p-0">
                        <div className="d-flex align-items-center gap-3">
                          <p className="font-16 font-medium table-title-color">
                            Software Compatibility
                          </p>
                          <span
                            className={`status-badge ${
                              val.deviceDetails.softwareDetailsDto.status ==
                              `Passed`
                                ? `ready`
                                : `hardware-refresh`
                            }   d-flex align-items-center gap-2 text-nowrap font-12 font-medium w-auto`}
                          >
                            <span className="status-legend" />
                            {val.deviceDetails.softwareDetailsDto.status}
                          </span>
                        </div>
                        <table className="inner-details-table mt-4">
                          <thead />
                          <tbody>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                Operating System:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {
                                  val.deviceDetails.softwareDetailsDto
                                    .operatingSystem
                                }
                              </td>
                              <td>
                                {/*{val.deviceDetails.softwareDetailsDto
                                  .operatingSystemStatus ? (
                                  <img
                                    src="images/green-tick-icon.svg"
                                    alt="green-tick-icon"
                                    className="green-tick-icon"
                                  />
                                ) : (
                                  <img
                                    src="images/red-cancel-icon.svg"
                                    alt="red-cancel-icon"
                                    className="red-cancel-icon"
                                  />
                                )}*/}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                # of Installed Apps:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {
                                  val.deviceDetails.softwareDetailsDto
                                    .numberOfInstalledApps
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                # of Supported Apps:
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {
                                  val.deviceDetails.softwareDetailsDto
                                    .numberOfSupportedApps
                                }
                              </td>
                            </tr>
                            <tr>
                              <td className="font-14 font-regular table-title-color border-0">
                                # of Unsupported Apps
                              </td>
                              <td className="font-14 font-regular secondary-text-color-dark border-0">
                                {
                                  val.deviceDetails.softwareDetailsDto
                                    .numberOfUnsupportedApps
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : null}
            </>
          );
          
        });
      }
    }
  };
  // PS_DD_42-SQ_EX_66: Loop through manufacturerDropdown and bind to AdvancedFilterPopup.js
  const bindManufacturer = () => {
    return manufacturersDropDown.map((manufacturer, index) => (
      <option key={index} value={manufacturer.value}>
        {manufacturer.value}
      </option>
    ));
  };
  // PS_DD_74-SQ_EX_95: Loop through manufacturerDropdown and bind to AdvancedFilterPopup.js
  const bindStatus = () => {
    return statusDropdown.map((status, index) => (
      <option key={index} value={status.value}>
        {status.value}
      </option>
    ));
  };

  const bindRecordOption = () => {
    let maxValue: number = totalcount > 50 ? 50 : Math.ceil(totalcount / 10) * 10;
    let dropdownValues: number[] = [];
    for (let i = 10; i <= maxValue; i += 10) {
        dropdownValues.push(i);
    }
    return(
      dropdownValues.map(value => (
        <option key={value} value={value}>
            {value}
        </option>
    ))
    )
  };
  // PS_DD_51-PS_DD_60: Define handleAccordionClick to toggle item visibility
  const handleAccordionClick = (index: number) => {
    if (index != null) {
      if ($(`#detailHead-${index}`).hasClass("collapsed")) {
        $(".header-arrow").addClass("collapsed");
        $(".content-row").removeClass("show");
        $(`#detailHead-${index}`).removeClass("collapsed");
        $(`#detail-${index}`).addClass("show");
        if ($(`#detailHead-${index}`).hasClass("device-accordian-opac")) {
          $(`#detailHead-${index}`).addClass("collapsed");
        }


      } else {
        $(".header-arrow").addClass("collapsed");
        $(".content-row").removeClass("show");
        if ($(`#detailHead-${index}`).hasClass("device-accordian-opac")) {
          $(`#detailHead-${index}`).addClass("collapsed");
        }
      }
    }
  };

  // PS_DD_68-SQ_EX_80: Resend mail logic
  const resendMail = async () => {
    let resendMailData: { emailId: string ,assessmentId: string}[] = [];
    if (deviceData != null) {
      // PS_DD_63: Map through the deviceDetailData and push emailId to resendMailData if mailCheckbox is true
      deviceData.forEach((device) => {
        if (device.mailCheckbox) {
          resendMailData.push({ 
            emailId: device.userName,
            assessmentId :assessmentId?.toString()
          });
        }
      });

      try {
        const response = await resendMailService(resendMailData);
        if (response.statusCode == 200) {
          setResendMailCount(0);
          pageLoad();
        }
      } catch (error:any) {
        handleError(error.message, "resendMail");
      }
    }
  };

  const handleError = async (error: any, functionName: any) => {

    const errorDetails: ErrorDetails = {
      errorMessage: error,
      errorFunction: functionName,
      FileName : "DeviceDetailsComponent.tsx",
    };
  
    // PS_DB_25: Use try-catch block within handleError function to mitigate issues while making a post API call.
    try {
      await postErrorlogs(errorDetails);
    } catch (postError) {
    }
  };

  // PS_DD_68-SQ_EX_91: Check for Enter key press and call pageLoad
  const checkKeyPress = (event: any) => {
    if (event.keyCode === 13) {
      pageLoad();
      statusLoad();
    }
  };

  // PS_DD_49-SQ_EX_75: Navigate logic
  const navigateToDashboard = () => {
    nav('/dashboard', { state: { assessmentName: assessmentName,assessmentId:assessmentId, organizationName: organizationName,managementType:managementType } })

  };

  return (
<>
  <NavHeader/>
  <Loader isLoading={loading} /> 
  <div className="container-fluid custom-container">
      <div className="container-fluid daskboard-bg p-4 pb-0 custom-container">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <ul
            className="nav custom-nav-tabs nav-tabs"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link bg-transparent ms-2"
                id="Dashboard-tab"
                data-bs-toggle="tab"
                data-bs-target="#Dashboard"
                type="button"
                role="tab"
                aria-controls="Dashboard"
                aria-selected="false"
                onClick={()=>navigateToDashboard()}
              >
                Dashboard
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link bg-transparent active"
                id="Devicedetails-tab"
                data-bs-toggle="tab"
                data-bs-target="#Devicedetails"
                type="button"
                role="tab"
                aria-controls="Devicedetails"
                aria-selected="true"
              >
                Device Details
              </button>
            </li>
          </ul>
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex align-items-center justify-content-center gap-3">
              <div className="position-relative custom-input-field p-2">
                <input
                  type="search"
                  className="border-0 font-13 font-regular ps-2 pe-4 bg-transparent"
                  placeholder="Search"
                  name="searchField"
                  onChange={(event) =>
                    onFieldChange("SearchField", event, sortColumn)
                  }
                  onKeyDown={checkKeyPress}
                />
                <button
                title="search"
                  className="btn bg-transparent py-3 search-btn"
                  onClick={searchOnclick}
                />
              </div>
              <div className="position-relative">
                <button
                  className="common-btn d-flex align-items-center show"
                  type="button"

                  onClick={advancedFileterPopup}
                >
                  <img
                    src="images/config-icon.svg"
                    alt="filter-icon"
                    className="filt-icon"
                  />
                </button>
               {advFilterPopup? <div
                  className="dropdown-menu dd-width shadow-sm p-4 border-0 show"
                  data-popper-placement="bottom-start"
                >
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="font-16 font-semibold table-title-color mb-0">
                        {" "}
                        Filter
                      </h5>
                      <button
                      title="manufacture"
                        type="button"
                        className="btn-close font-12"
                        onClick={()=>advancedFileterPopup()}
                      />
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="Organization"
                            className="form-label table-title-color font-14 font-regular"
                          >
                            Manufacturer
                          </label>
                          <div className="dropdown custom-select-field position-relative">
                            <select
                            title="manufacturer"
                              className="btn dropdown-btn font-14 font-regular secondary-text-color-dark"
                              onChange={(event) =>
                                onFieldChange("manufacturer", event, sortColumn)
                              }
                              value={manufacturer}
                            >
                              <option value="">Select</option>
                              {bindManufacturer()}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="Status"
                            className="form-label table-title-color font-14 font-regular"
                          >
                            Status
                          </label>
                          <div className="dropdown custom-select-field position-relative">
                            <select
                            title="manufacturer"
                              className="btn dropdown-btn font-14 font-regular secondary-text-color-dark"
                              value={status}
                              onChange={(event) =>
                                onFieldChange("status", event, sortColumn)
                              }
                            >
                              <option value="">Select</option>
                              {bindStatus()}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-3">
                      <button
                        className="outline-btn popup-btn-v2 mt-2 mb-3 me-2 px-4 p-2 font-14 font-medium"
                        type="button"
                        aria-label="Close"
                        onClick={clearButtonClick}
                      >
                        Clear All
                      </button>
                      <button
                        className="primary-btn popup-btn-v2 mt-2 mb-3 ms-2 px-3 font-14 font-medium"
                        type="button"
                        aria-label="Close"
                        onClick={searchOnclick}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>:null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="tab-pane show"
        id="Devicedetails"
        role="tabpanel"
        aria-labelledby="Devicedetails-tab"
      >
        {/* table start */}
        <div className="table-responsive mt-2 acc-details-table">
          <table className="table">
            <thead>
              <tr>
                <th className="border-0 p-4" />
                <th className="border-0">
                  <div className="d-flex align-items-center gap-3">
                    {/*<input className="form-check-input" type="checkbox" />*/}
                    Username
                  </div>
                </th>
                <th className="border-0">
                  Device Name{" "}
                  <span
                    className="ms-1 cursor-pointer"
                    onClick={(event) =>
                      onFieldChange("sort", event, "device_name")
                    }
                  >
                    {deviceSort == "asc" ? (
                      <img src="images/sort-up-arrow.svg" alt="sort-up-arrow" />
                    ) : (
                      <img
                        src="images/sort-down-arrow.svg"
                        alt="sort-down-arrow"
                      />
                    )}
                  </span>
                </th>
                <th className="border-0 text-center">
                  Status{" "}
                  <span
                    className="ms-1 cursor-pointer"
                    onClick={(event) =>
                      onFieldChange("sort", event, "assessment_status")
                    }
                  >
                    {statusSort == "asc" ? (
                      <img src="images/sort-up-arrow.svg" alt="sort-up-arrow" />
                    ) : (
                      <img
                        src="images/sort-down-arrow.svg"
                        alt="sort-down-arrow"
                      />
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {totalcount > 0 ? (
                bindDeviceDetail()
              ) : (
                <tr className="border-transparent">
                <td colSpan={4}>
                    <div className="d-flex align-items-center justify-content-center flex-column py-5">
                        <img src="images/no-data-found-img.svg" className="mb-5" alt="no-data-found"></img>
                        <p className="font-semibold font-16 text-dark mb-4">No device Found</p>
                    </div>
                </td>
            </tr>

              )}
            </tbody>
          </table>
        </div>
        {/* table end */}
        {totalcount > 0 ? (
          <div className="d-flex align-items-center justify-content-between gap-3 bottom-0 position-relative cust-width">
            <div className="d-flex align-items-center justify-content-center flex-nowrap gap-3 ">
              <span className="font-regular font-13 secondary-text-color-light">
                Showing{" "}
                <span>
                  {loadmore <= totalcount ? retriveDataLength : totalcount}
                </span>{" "}
                of
                <span> {totalcount} </span>
                 records
              </span>
              <div className="d-flex align-items-center gap-2">
                <select
                title="recordcount"
                  className="form-field p-1 px-2"
                  value={recordCount}
                  onChange={(event) =>
                    onFieldChange("recordCount", event, sortColumn)
                  }
                >{bindRecordOption()}
                 {/* <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>*/}
                </select>
                <span className="font-regular font-13 secondary-text-color-light">
                  lines per page
                </span>
              </div>
            </div>
            {loadmore >= totalcount ? null : (
              <button
                className="outline-btn-2  py-2 rounded-2 font-14 font-regular"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => loadmoreButtonClick()}
              >
                Load More
              </button>
            )}
          </div>
        ) : null}
      </div>
      {resendMailCount > 0 ? (
        <div
          className="position-fixed bottom-0 start-50 translate-middle-x custom-live-toast show"
          style={{ zIndex: 11 }}
        >
          <div
            id="liveToast"
            className="toast  custom-toast border-0 show"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-body d-flex align-items-center justify-content-between">
              <p className="font-regular font-16 secondary-text-color-dark mb-0">
                <span className="font-medium font-20 color-black-v1 me-2">
                  {resendMailCount}
                </span>
                User selected
              </p>
              <button
                className="gradient-btn font-14 font-medium border-0 d-flex align-items-center"
                onClick={resendMail}
              >
                Resend Mail
              </button>
            </div>
          </div>
        </div>
      ) : null}

</div>
</>
  );
};

