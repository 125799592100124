export const DeviceDetailConstant = {
    getDeviceDetail: "/getDeviceByAssessmentId",
    getStatusData: "/getDeviceFilterDropdowns",
    resendMailServiceConst: "/sendEmail"
}

export const getUnsupportedAppsConst = {
    getAppDetail: "/getUnsupportedDevices",

}

// PS_DB_115: Define constant API_BASE_URL to standardize the base URL for all backend API service calls.
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// PS_DB_116: Assign string '/getDashboardData' to constant GET_DASHBOARD_DATA_ENDPOINT for fetching dashboard-related data.
export const GET_DASHBOARD_DATA_ENDPOINT = process.env.REACT_APP_GET_DASHBOARD_DATA_ENDPOINT;
 
// PS_DB_117: Set constant GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT with the endpoint string for exporting device details data.
export const GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT = process.env.REACT_APP_GET_EXPORT_DEVICEDETAILS_DATA_ENDPOINT;
 
// PS_DB_118: Define constant ERROR_LOG_ENDPOINT for the endpoint to which error log data will be sent.
export const ERROR_LOG_ENDPOINT = process.env.REACT_APP_ERROR_LOG_ENDPOINT;