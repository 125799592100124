
// PS_AE_02 - Import dependencies (React, useState, useEffect)
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
// PS_AE_05 - Import XLSX library and file-saver
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { Organization, AssessmentFormData, TemplateData, ActiveDirectoryCredentials, 
    UnmanagedCredential, ApiResponse, AssessmentDetails, AddUpdateAssessmentResponse,
    ExcelRow, ValidationResult 
 } from '../Interface/FormInterface';
import { addAssessment, fetchFilterOptions, postErrorLog, uploadObject } from '../Services/api';
import { AxiosResponse } from 'axios';
import { EditData } from '../Interface/GridInterface';
import Loader from './Loader';

interface FormErrors {
    [key: string]: string | undefined;
  }
  declare global {
    interface Window {
      bootstrap: any;
    }
  }
  interface AddEditFormProps {
    onClose?: () => void;
    onSubmitSuccess?: () => void;
    isPopup?: boolean;
    editData?: EditData | null;
  }
// PS_AE_06 - Declare AddAssessment as React.FC
const AddEditForm: React.FC<AddEditFormProps> =  ({ onClose, onSubmitSuccess, isPopup = false, editData = null })  => {
  // PS_AE_07 - Initialize state using useState hooks for formData, organizations, etc.
  const [formData, setFormData] = useState<AssessmentFormData>({
    assessmentId: '',
    organizationId: '',
    organizationName: '',
    organizationLogo: '',
    organizationLogoName: null,
    assessmentName: '',
    deviceManagement: 'Active Directory',
    activeCredentials: {
      ldapServerName: '',
      username: '',
      password: ''
    },
    UnmanagedCredentials: {
        credentials: [],
        notifyAll: true  // Set to true by default
      }
  });
  const [orgNameError, setOrgNameError] = useState<string | null>(null);
  // HIGHLIGHT: New state for organization input value
  const [orgInputValue, setOrgInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [errors, setErrors] = useState<FormErrors>({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [excelData, setExcelData] = useState<ExcelRow[]>([]);
  const [invalidRowCount, setInvalidRowCount] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAddingNewOrg, setIsAddingNewOrg] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  
//PS_AE_08 - PS_AE_10
useEffect(() => {
  if (editData) {
    let organizationLogoName = null;
    if (editData.organizationLogo) {
      try {
        const url = new URL(editData.organizationLogo);
        const pathnameParts = url.pathname.split("/");
        const filename = pathnameParts[pathnameParts.length - 1];
        const extension = filename.split('.').pop();
        
        organizationLogoName = `${editData.organizationName}.${extension}`;
      } catch (error) {
        console.error("Invalid organization logo URL:", editData.organizationLogo);
        // If URL parsing fails, we'll still try to extract an extension
        const parts = editData.organizationLogo.split('.');
        const extension = parts.length > 1 ? parts.pop() : 'png'; // Default to 'png' if no extension found
        organizationLogoName = `${editData.organizationName}.${extension}`;
      }
    }

    setFormData({
      assessmentId: editData.assessmentId,
      organizationId: editData.organizationId,
      organizationName: editData.organizationName,
      organizationLogo: editData.organizationLogo,
      organizationLogoName: organizationLogoName,
      assessmentName: editData.assessmentName,
      deviceManagement: editData.management_type === 'Active Directory' ? 'Active Directory' : 'Unmanaged',
      activeCredentials: {
        ldapServerName: editData.ldap_server_name,
        username: editData.ldap_user_name,
        password: editData.ldap_password
      },
      UnmanagedCredentials: {
        credentials: editData.UnmanagedCredentials || [],
        notifyAll: false // You might want to add this to your EditData if it's needed
      }
    });
    setIsEditMode(true);
  } 
  else {
    fetchOrganizations();
    generateDefaultAssessmentName();
  }
}, [editData]);
  
  //PS_AE_36 - PS_AE_39
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  // PS_AE_11 - PS_AE_21
  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      // PS_AE_12 - Try block: Call fetchFilterOptions() from API
      const response:AxiosResponse = await fetchFilterOptions();
      
      // PS_AE_17 - Update state with organizations if successful
      if (response.status === 200) {
        setOrganizations(response.data.data.organizations);
      } else {
        // PS_AE_18 - Log error if fetch unsuccessful
        let errorData:object = {
          errorFunction: "fetchOrganizations",
          errorMessage: "Error fetching organizations:"+response.data,
          FileName: "AddEditForm.tsx"
      }
      postErrorLog(errorData);
      }
    } catch (error:any) {
      // PS_AE_19 - Catch block: If an exception occurs, catch the error
      // PS_AE_20 - Create errorData variable with FileName, functionName, and stackTrace
      let errorData:object = {
        errorFunction: "fetchOrganizations",
        errorMessage: error.message,
        FileName: "AddEditForm.tsx"
    }
    postErrorLog(errorData);
  }
  setLoading(false);
  }
  
  // PS_AE_22 - PS_AE_25 Define generateDefaultAssessmentName function
  const generateDefaultAssessmentName = () => {
    // PS_AE_23 - Generate a four-digit random number
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    
    // PS_AE_24 - Concatenate 'Assessment_' with random number to create default name
    const defaultName = `Assessment_${randomNumber}`;
    
    // PS_AE_25 - Update formData state with setFormData
    setFormData(prevState => ({
      ...prevState,
      assessmentName: defaultName
    }));
  };

  // PS_AE_26 - PS_AE_29 Define handleInputChange function with parameter e: React.ChangeEvent<HTMLInputElement>
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    
    if (errors[name]) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  const handleOrgInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrgInputValue(e.target.value);
    setOrgNameError(null);
  };
  //PS_AE_40 - PS_AE_43
  const handleOrganizationSelect = (orgName: string, orgId: string) => {
    setFormData(prev => ({ 
      ...prev, 
      organizationName: orgName,
      organizationId: orgId
    }));
    setIsDropdownOpen(false);
    setOrgNameError(null);
  };

  //PS_AE_44 - PS_AE_46
  const handleAddNewOrg = () => {
    setIsAddingNewOrg(true);
    setOrgInputValue('');
    setIsDropdownOpen(false);
    setOrgNameError(null);
    // HIGHLIGHT: Clear the previously selected organization
    setFormData(prev => ({
      ...prev,
      organizationName: '',
      organizationId: ''
    }));
  };
  //PS_AE_47 - PS_AE_49
  const handleTickClick = () => {
    if (orgInputValue.trim()) {
      const existingOrg = organizations.find(
        org => org.organizationName.toLowerCase() === orgInputValue.trim().toLowerCase()
      );

      if (existingOrg) {
        setIsAddingNewOrg(false);
        setOrgInputValue(''); // Clear the input
        setOrgNameError("This organization already exist");
      } else {
        const newOrg: Organization = {
          organizationId: organizations.length + 1,
          organizationName: orgInputValue.trim()
        };
        setOrganizations(prev => [...prev, newOrg]);
        setFormData(prev => ({
          ...prev,
          organizationName: newOrg.organizationName,
          organizationId: newOrg.organizationId.toString()
        }));
        setIsAddingNewOrg(false);
        setOrgNameError(null);
        // Here you would typically make an API call to add the new organization to your backend
      }
    } else {
      setIsAddingNewOrg(false);
      setOrgNameError(null);
    }
  };
  
  
    // PS_AE_33 - PS_AE_35 Define handleDeviceManagementChange function with parameter e: React.ChangeEvent<HTMLInputElement>
    const handleDeviceManagementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // HIGHLIGHT: Changed to use the new value directly
      const newDeviceManagement = e.target.value as 'Active Directory' | 'Unmanaged';
    
      setFormData(prevState => ({
        ...prevState,
        deviceManagement: newDeviceManagement,
        activeCredentials: newDeviceManagement === 'Active Directory' ? prevState.activeCredentials : {
          ldapServerName: '',
          username: '',
          password: ''
        },
        UnmanagedCredentials: newDeviceManagement === 'Unmanaged' ? {
          credentials: [],
          notifyAll: true
        } : { credentials: [], notifyAll: false }
      }));
    
      // HIGHLIGHT: New code block to clear errors
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        if (newDeviceManagement === 'Active Directory') {
          delete newErrors.file;
        } else {
          delete newErrors.ldapServerName;
          delete newErrors.username;
          delete newErrors.password;
        }
        return newErrors;
      });
    
      // HIGHLIGHT: New code block to clear excel data and reset file input
      if (newDeviceManagement === 'Active Directory') {
        setExcelData([]);
        const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
        if (fileInput) {
          fileInput.value = '';
        }
      }
    };
  // PS_AE_30 - PS_AE_32 Define handleActiveCredentialsChange function with parameter e: React.ChangeEvent<HTMLInputElement>
  const handleActiveCredentialsChange = (e: ChangeEvent<HTMLInputElement>) => {
    // PS_AE_31 - Destructure e.target to get name and value
    const { name, value } = e.target;
    
    // PS_AE_32 - Update formData state with setFormData
    setFormData(prevState => ({
      ...prevState,
      activeCredentials: {
        ...prevState.activeCredentials,
        [name]: value
      }
    }));
  };
  // PS_AE_50 - PS_AE_59
  const handleDownloadTemplateClick = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Template');
  
    worksheet.columns = [
      { header: 'User Name', key: 'userName', width: 15 },
      { header: 'Email ID', key: 'emailId', width: 20 },
      { header: 'Device ID', key: 'deviceId', width: 15 },
    ];
  
    // Apply bold style to the header row
    worksheet.getRow(1).font = { bold: true };
  
    workbook.xlsx.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Win Remedy Template.xlsx';
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  // PS_AE_60 - PS_AE_63
  const validateRow = (row: ExcelRow): ValidationResult => {
    const errors: { [key: string]: string } = {};
  
    // Use optional chaining to call trim() only if row['Email ID'] is not undefined,
    // otherwise use an empty string which will fail the !row['Email ID'] check anyway
    const email = row['Email ID']?.trim() || '';
  
    if (!email) {
      errors['Email ID'] = 'Email ID cannot be empty';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors['Email ID'] = 'Invalid email format';
    }
  
    // Continue with other validations if needed...
  
    return {
      isValid: Object.keys(errors).length === 0,
      errors
    };
  };
   // PS_AE_65 - PS_AE_79
   const parseExcelFile = (file: File): Promise<ExcelRow[]> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const data = e.target?.result;
        if (typeof data === 'string') {
          const workbook = XLSX.read(data, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const json = XLSX.utils.sheet_to_json<ExcelRow>(worksheet, { 
            header: ['User Name', 'Email ID', 'Device ID'],
            range: 1 
        });
          resolve(json);
        } else {
          reject(new Error('Failed to read file data'));
        }
      };
  
      reader.onerror = (error) => {
        reject(error);
      };
  
      reader.readAsBinaryString(file);
    });
  };
// PS_AE_80 - PS_AE_85
const validateForm = (): FormErrors => {
    let newErrors: FormErrors = {}; 
  
    if (!formData.organizationName) newErrors.organizationName = "Organization name is required";
    if (!formData.assessmentName) newErrors.assessmentName = "Assessment Name is required";


    if (formData.deviceManagement === 'Active Directory') {
        if (!formData.activeCredentials.ldapServerName) newErrors.ldapServerName = "LDAP Server Name is required";
        if (!formData.activeCredentials.username) newErrors.username = "Username is required";
        if (!formData.activeCredentials.password) newErrors.password = "Password is required";
      }else if (formData.deviceManagement === 'Unmanaged'&& !isEditMode) {
        // Validate 'Unmanaged' credentials
        if (formData.UnmanagedCredentials.credentials.length === 0) {
          newErrors.file = "Please upload the template file";
        }
        // We don't need to validate the notifyAll checkbox as it's optional
      }
    return newErrors; 
  };
  
  //PS_AE_87 - PS_AE_96
const handleLogoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    
    const allowedExtensions = ['png', 'jpg', 'jpeg', 'svg'];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    // Check file extension and size
    const isFileTypeValid = allowedExtensions.includes(fileExtension || '');
    const isFileSizeValid = file.size <= 5 * 1024 * 1024; // 5MB limit

    if (isFileTypeValid && isFileSizeValid) {
        try {
            setLoading(true);
            
            // Create FormData here to log it

            // Call the API to upload the file
            const response: AxiosResponse = await uploadObject(file);
            
            // Check if the response is successful and contains the URL
            if (response.data.statusCode === 200 && response.data.status === "Success" && response.data.data) {
                const logoUrl = response.data.data; // The URL is directly in the data field
                
                const organizationName = formData.organizationName || 'organization';
                const newFileName = `${organizationName}.${fileExtension}`;

                setFormData(prevState => ({
                    ...prevState,
                    organizationLogo: logoUrl, // Set the URL returned from the API
                    organizationLogoName: newFileName
                }));

                // Clear any existing error for organizationLogo
                setErrors(prevErrors => {
                    const newErrors = { ...prevErrors };
                    delete newErrors.organizationLogo;
                    return newErrors;
                });
            } else {
                throw new Error("Failed to upload logo: " + (response.data.errorMessage || "Unknown error"));
            }
        } catch (error: any) {
            let errorData: object = {
                errorFunction: "handleLogoChange",
                errorMessage: error.message,
                errorFileName: "AddEditForm.tsx"
            };
            
            await postErrorLog(errorData);
            setErrors(prevErrors => ({
                ...prevErrors,
                organizationLogo: "Failed to upload logo. Please try again."
            }));
        } finally {
            setLoading(false);
        }
    } else {
        let errorMessage = '';
        if (!isFileTypeValid) {
            errorMessage = "Please upload a file in .png, .jpg, .jpeg, .svg, or .pdf format.";
        } else if (!isFileSizeValid) {
            errorMessage = "File is too large. Size limit is 5MB.";
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            organizationLogo: errorMessage
        }));
    }
};


// PS_AE_98 - PS_AE_114
const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const isXlsx = file.name.endsWith('.xlsx');
      const fileSize = file.size <= 5 * 1024 * 1024; // <= 5MB
      
      if (isXlsx && fileSize) {
        try {
          const data = await parseExcelFile(file);
          setExcelData(data);
  
          const credentials: UnmanagedCredential[] = data.map(row => ({
            username: row['User Name'] || null,
            deviceName: row['Device ID'] || null,
            emailId: row['Email ID']
          }));
  
          setFormData(prevState => ({
            ...prevState,
            UnmanagedCredentials: {
              ...prevState.UnmanagedCredentials,
              credentials: credentials
            }
          }));
  
  
        } catch (error) {
          console.error("Error parsing file:", error);
          setErrors(prevErrors => ({
            ...prevErrors,
            file: 'Error parsing Excel file. Please try again.'
          }));
        }
      } else {
        setErrors({
          file: 'Please upload a .xlsx file not larger than 5MB.'
        });
      }
    }
  };
  
  
 // PS_AE_116 - PS_AE_143
const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = validateForm();
    
    if (excelData.length > 0) {
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      
      let invalidCount = 0;
      excelData.forEach((row, index) => {
        const validationResult = validateRow(row);
        if (!validationResult.isValid) {
          invalidCount++;
          Object.keys(validationResult.errors).forEach(key => {
            const cellAddress = XLSX.utils.encode_cell({ r: index + 1, c: ['User Name', 'Email ID', 'Device ID'].indexOf(key) });
            if (!worksheet[cellAddress].c) worksheet[cellAddress].c = [];
            worksheet[cellAddress].c.push({ a: 'SheetJS', t: validationResult.errors[key] });
          });
        }
      });
  
      setInvalidRowCount(invalidCount);
      
      if (invalidCount > 0) {
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(data, 'Validated_Win_Remedy_Template.xlsx');
        
        newErrors.file = `${invalidCount} rows are not valid. Please check the downloaded Excel file for error messages.`;
      }
    }
    
    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        const payload = {
          organizationId: formData.organizationId,
          assessmentId: isEditMode ? formData.assessmentId : undefined, // Include assessmentId only for edit mode
          organizationName: formData.organizationName,
          assessmentName: formData.assessmentName,
          organizationLogo: formData.organizationLogo,
          deviceManagement: formData.deviceManagement,
          activeCredentials: (formData.deviceManagement === 'Active Directory') ? {
            ldapServerName: formData.activeCredentials.ldapServerName,
            ldapUsername: formData.activeCredentials.username,
            ldapPassword: formData.activeCredentials.password
          } : undefined,
          unmanagedData: (formData.deviceManagement === 'Unmanaged') ? {
            credentials: formData.UnmanagedCredentials.credentials,
            notifyAll: formData.UnmanagedCredentials.notifyAll
          } : undefined
        };
        
        const response:AxiosResponse = await addAssessment(payload);
        if (response.status === 200) {
          if (onSubmitSuccess) {
            onSubmitSuccess();
          }
        }  else {
          throw new Error(response.data.ErrorMessage || "An unexpected error occurred");
        }
      } catch (error:any) {
        console.error('Error submitting form:', error);
      
        // HIGHLIGHT: Updated error handling
        if (error.response && error.response.data) {
          // If it's an Axios error with a response
          if (error.response.status === 400 && error.response.data.ErrorMessage === "assessmentName already present") {
            setErrors(prevErrors => ({
              ...prevErrors,
              assessmentName: "This assessment name already exist"
            }));
          } else if (error.response.data.ErrorMessage === "Failed to get LDAP Server Data") {
            setErrors(prevErrors => ({
              ...prevErrors,
              password: "Invalid Credentials"
            }));
          } else {
            setErrors(prevErrors => ({
              ...prevErrors,
              password: error.response.data.ErrorMessage || "An unexpected error occurred"
            }));
          }
        } else {
          // For other types of errors
          setErrors(prevErrors => ({
            ...prevErrors,
            password: "An unexpected error occurred. Please try again."
          }));
        }
        let errorData:object = {
          errorFunction: "handleSubmit",
          errorMessage: error.message,
          FileName: "AddEditForm.tsx"
      }
      postErrorLog(errorData);
      }
    } else {
      setErrors(newErrors);
    }
    setLoading(false);
  };
  //PS_AE_146 - PS_AE_147
  const handleCancel = () => {
    // Using the state structure you provided to reset the form fields
    setFormData({
      assessmentId: '',
      organizationId: '',
      organizationName: '',
      organizationLogo: '',
      organizationLogoName: null,
      assessmentName: '',
      deviceManagement: 'Active Directory',
      activeCredentials: {
        ldapServerName: '',
        username: '',
        password: ''
      },
      UnmanagedCredentials: {
        credentials: [],
        notifyAll: true  // Set to true by default
      }
    });
    if (onClose) {
        onClose();
      }
    // Close the popup modal here, if applicable
  };
  return (
    <>
    <Loader isLoading={loading} /> 
    {/* <div className="modal fade" id="staticBackdrop-4" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
    <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
        <div className="modal-content custom-popup border-0 p-4">
            <div className="modal-header border-0 p-0">
                <h1 className="modal-title font-18 font-semibold secondary-text-color-dark" id="staticBackdropLabel">
                    {isEditMode ? 'Edit Assessment' : 'Add Assessment'}
                </h1>
                <button type="button" className="btn shadow-none px-0" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                    <img src="images/popup-close icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                </button>
            </div>

            <div className="modal-body p-0 mt-4 popup-form">
                <div className="row">
                <div className="col-md-6 mb-4 px-3">
  <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="organization-name">
    Organization Name <span className="required">*</span>
  </label>
  <div className="custom-select-field position-relative" ref={dropdownRef}>
    {isAddingNewOrg ? (
      <div className="input-group">
        <input
          type="text"
          className="form-control font-14 font-medium border-right-0"
          placeholder="Enter new organization name"
          name="organizationName"
          value={orgInputValue}
          onChange={handleOrgInputChange}
          disabled={isEditMode}
        />
        <button 
          className="btn btn-outline-secondary input-tick" 
          type="button"
          onClick={handleTickClick}
          disabled={isEditMode}
        >
          <img src="images/select-tick-icon.svg" alt="tick icon" />
        </button>
      </div>
    ) : (
      <div className="dropdown">
        <button 
          className="btn form-control text-left d-flex justify-content-between align-items-center"
          type="button"
          onClick={() => !isEditMode && setIsDropdownOpen(!isDropdownOpen)}
          disabled={isEditMode}
        >
          {formData.organizationName || 'Select Organization Name'}
          <img 
            title='arrow'
            src={isDropdownOpen ? "images/select-up-arrow.svg" : "images/select-down-arrow.svg"} 
            className="custom-select-icon" 
          />
        </button>
        {isDropdownOpen && !isEditMode && (
          <ul className="dropdown-menu show w-100">
            <li>
              <button 
                className="dropdown-item d-flex align-items-center gap-2"
                type="button"
                onClick={handleAddNewOrg}
              >
                <img src="images/plus-icon-dropdown.svg" className="plus-icon" alt="plus icon" /> 
                Add New Organization
              </button>
            </li>
            <li><hr className="dropdown-divider" /></li>
            {organizations.map((org) => (
              <li key={org.organizationId}>
                <button 
                  className="dropdown-item"
                  type="button"
                  onClick={() => handleOrganizationSelect(org.organizationName, org.organizationId.toString())}
                >
                  {org.organizationName}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    )}
  </div>
  {orgNameError && <span className="required font-11 font-regular">{orgNameError}</span>}
  {errors.organizationName && <span className="required font-11 font-regular">{errors.organizationName}</span>}
</div>
    {!formData.organizationLogo && (
                    <div className="col-md-6 mb-4 px-3">
                        <label className="form-label font-14 font-regular secondary-text-color-dark d-block" htmlFor="organization-logo">Organization Logo</label>
                        <button className="my-2 position-relative border-0 bg-transparent">
                            <img src="images/Browse icon.svg" alt="Browse-icon" className="browse-icon cursor-pointer" />
                            <span className="font-regular font-12 ms-2 grey-text-color-light-v2 cursor-pointer">Browse</span>
                            <input
                             title='logo' 
                             placeholder='Enter'
                             name="organizationLogo"
                             type="file" 
                             accept=".png,.jpg,.jpeg,.svg"
                             className="cust-file-upload form-control custom-file bg-color-v6" 
                             id="uploadLogo" 
                             onChange={handleLogoChange} 
                             />
                             
                        </button>
                        {errors.organizationLogo && <span className="required font-11 font-regular d-block">{errors.organizationLogo}</span>}
                    </div>
)}
                    {(formData.organizationLogo || formData.organizationLogoName) && (
    <div className="col-md-6 mb-4 px-3">
        <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="organization-logo">Organization Logo</label>
        <div className="my-2 d-flex align-items-center">
            <img 
                src={formData.organizationLogo || '/images/profile-logo.svg'} // HIGHLIGHT: Changed this line
                alt="organization-logo" 
                className="organization-logo" 
                style={{maxWidth: '100px', maxHeight: '100px'}}
                onError={(e) => {
                    e.currentTarget.onerror = null; // prevents looping
                    e.currentTarget.src = '/images/profile-logo.svg';
                }}
            />
            <span className="font-regular font-12 ms-2 grey-text-color-light-v2 cursor-pointer">
                {formData.organizationLogoName || 'Default Logo'}
            </span>
            <img 
                src="images/close-icon.svg" 
                alt="close-icon" 
                className="close-icon ms-2 cursor-pointer" 
                onClick={() => setFormData(prev => ({ 
                    ...prev, 
                    organizationLogo: null, // HIGHLIGHT: Changed to null
                    organizationLogoName: null 
                }))} 
            />
        </div>
    </div>
)}

                    <div className="col-md-6 mb-4 px-3">
                        <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="assessment-name">Assessment Name</label>
                        <input 
                            id="assessment-name" 
                            type="text" 
                            className="form-control font-14 font-medium" 
                            placeholder="Enter Assessment Name"
                            name="assessmentName"
                            value={formData.assessmentName}
                            onChange={handleInputChange}
                        />
                        {errors.assessmentName && <span className="required font-11 font-regular">{errors.assessmentName}</span>}
                    </div>

                    <div className="col-md-6 mb-4 px-3">
                        <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="device-management">Device Management <span className="required">*</span></label>
                        <div className="d-flex align-items-center gap-3 my-2">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name="deviceManagement" 
                                    id="activeDirectory"
                                    value="Active Directory"
                                    checked={formData.deviceManagement === 'Active Directory'}
                                    onChange={handleDeviceManagementChange}
                                    disabled={isEditMode}
                                />
                                <label className="form-check-label font-13 font-regular secondary-text-color-dark" htmlFor="activeDirectory">
                                    Active Directory
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name="deviceManagement" 
                                    id="unmanaged"
                                    value="Unmanaged"
                                    checked={formData.deviceManagement === 'Unmanaged'}
                                    onChange={handleDeviceManagementChange}
                                    disabled={isEditMode}
                                />
                                <label className="form-check-label font-13 font-regular secondary-text-color-dark" htmlFor="unmanaged">
                                    Unmanaged
                                </label>
                            </div>
                        </div>
                        {errors.deviceManagement && <span className="required font-11 font-regular">{errors.deviceManagement}</span>}
                    </div>

                    {formData.deviceManagement === 'Active Directory' && (
                        <>
                            <div className="col-md-12 mb-3 px-3">
                                <p className="font-14 font-medium secondary-text-color-dark">Credentials</p>
                                {errors.password && <span className="required font-11 font-regular">{errors.password}</span>}
                            </div>

                            <div className="col-md-6 mb-4 px-3">
                                <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="server-name">LDAP Server Name <span className="required">*</span></label>
                                <input 
                                            id="server-name" 
                                            type="text" 
                                            className="form-control font-14 font-medium" 
                                            placeholder="Enter LDAP Server Name"
                                            name="ldapServerName"
                                            value={formData.activeCredentials.ldapServerName}
                                            onChange={(e) => handleActiveCredentialsChange(e)}
                                            disabled={isEditMode}
                                        />
                                        {errors.ldapServerName && <span className="required font-11 font-regular">{errors.ldapServerName}</span>}
                                    </div>

                                    <div className="col-md-6 mb-4 px-3">
                                        <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="username">Username <span className="required">*</span></label>
                                        <input 
                                            id="username" 
                                            type="text" 
                                            className="form-control font-14 font-medium" 
                                            placeholder="Enter Username"
                                            name="username"
                                            value={formData.activeCredentials.username}
                                            onChange={(e) => handleActiveCredentialsChange(e)}
                                            disabled={isEditMode}
                                        />
                                        {errors.username && <span className="required font-11 font-regular">{errors.username}</span>}
                                    </div>

                                    <div className="col-md-6 mb-4 px-3 position-relative">
                                        <label className="form-label font-14 font-regular secondary-text-color-dark" htmlFor="Password">Password <span className="required">*</span></label>
                                        <input 
  id="Password" 
  type={showPassword ? "text" : "password"} 
  className="form-control password-field font-14 font-medium custom-password-field" 
  placeholder="Enter Password"
  name="password"
  value={formData.activeCredentials.password}
  onChange={(e) => handleActiveCredentialsChange(e)}
  disabled={isEditMode}
/>
                                        <img 
                                            src={showPassword ? "images/pass-hidden-grey.svg" : "images/pass-open-grey.svg"} 
                                            className="pass-eye" 
                                            alt={showPassword ? "hide password" : "show password"}
                                            onClick={() => setShowPassword(!showPassword)}
                                            // style={{cursor: 'pointer', position: 'absolute', right: '20px', top: '48px'}}
                                        />
                                       
                                    </div>
                                </>
                            )}

                            
                        </div>
                        {formData.deviceManagement === 'Unmanaged' && (
  <div>
    <div className="col-md-12 mb-3 px-3">
      <p className="font-15 font-medium secondary-text-color-dark">Upload Details <span className="required">*</span></p>
      <div className={`upload-file-area p-4 mt-3 text-center ${isEditMode ? 'disabled-upload' : 'cursor-pointer'}`}>
        {isEditMode || formData.UnmanagedCredentials.credentials.length > 0 ? (
          <>
            <span className="font-regular font-12 ms-2 grey-text-color-light-v2">
              Win Remedy Template.xlsx
            </span>
            {!isEditMode && (
              <img 
                src="/images/close-icon.svg" 
                alt="close-icon" 
                className="close-icon ms-2 cursor-pointer" 
                onClick={() => {
                  setFormData(prev => ({
                    ...prev,
                    UnmanagedCredentials: {
                      ...prev.UnmanagedCredentials,
                      credentials: []
                    }
                  }));
                  setExcelData([]);
                  const fileInput = document.getElementById('fileUpload') as HTMLInputElement;
                  if (fileInput) {
                    fileInput.value = '';
                  }
                }} 
              />
            )}
          </>
        ) : (
          <button className="my-2 position-relative border-0 bg-transparent">
            <img src="images/File-upload-blue-icon.svg" alt="File-upload-blue-icon" className="File-upload-blue-icon" />
            <p className="mt-2 font-12 font-regular grey-text-color-dark">Browse or drag and drop file</p>
            <input 
              title='file'
              type="file" 
              accept=".xlsx,.xls"
              className="cust-file-upload form-control custom-file bg-color-v6" 
              onChange={handleFileUpload} 
              id="fileUpload"
            />
          </button>
        )}
        <p className="mt-2 font-12 font-light grey-text-color-dark">
          Use this template to add info - <span className={`blue-text-color font-regular ${isEditMode ? 'disabled-link' : 'cursor-pointer'}`} onClick={!isEditMode ? handleDownloadTemplateClick : undefined}>Download Template</span>
        </p>
      </div>
      {errors.file && <span className="required font-11 font-regular d-block mt-1">{errors.file}</span>}
    </div>
    
    <div className="col-md-12 mb-3 px-3">
      <div className="form-check">
        <input 
          className="form-check-input" 
          type="checkbox" 
          id="notifyAll"
          checked={formData.UnmanagedCredentials.notifyAll}
          onChange={(e) => setFormData(prev => ({
            ...prev,
            UnmanagedCredentials: {
              ...prev.UnmanagedCredentials,
              notifyAll: e.target.checked
            }
          }))}
          disabled={isEditMode}
        />
        <label className="form-check-label font-11 font-regular secondary-text-color-dark" htmlFor="notifyAll">
          Notify all
        </label>
      </div>
    </div>
  </div>
)}



                            
<div className="d-flex align-items-center justify-content-end mt-2 mb-3">
  <button 
    className="outline-btn popup-btn-v2 me-2" 
    type="button" 
    data-bs-dismiss="modal" 
    aria-label="Close"
    onClick={handleCancel}
  >
    Cancel
  </button>
  {isEditMode ? (
    <button 
      className={`primary-btn popup-btn-v2 ms-2 ${
        formData.organizationName && formData.organizationLogo && formData.assessmentName
          ? ''
          : 'disabled'
      }`} 
      type="button" 
      onClick={handleSubmit}
      disabled={!(formData.organizationName && formData.assessmentName)}
    >
      Update
    </button>
  ) : (
    formData.deviceManagement === 'Active Directory' ? (
      !formData.organizationName || !formData.assessmentName || !formData.activeCredentials.ldapServerName || !formData.activeCredentials.password || !formData.activeCredentials.username ? (
        <button 
          className="primary-button popup-btn-v2 ms-2" 
          type="button" 
          disabled
        >
          Save
        </button>
      ) : (
        <button 
          className="primary-btn popup-btn-v2 ms-2" 
          type="button" 
          onClick={handleSubmit}
        >
          Save
        </button>
      )
    ) : (
      !formData.organizationName || !formData.assessmentName || !formData.UnmanagedCredentials.credentials || !formData.UnmanagedCredentials.notifyAll || !excelData[0] ? (
        <button 
          className="primary-button popup-btn-v2 ms-2" 
          type="button" 
          disabled
        >
          Save
        </button>
      ) : (
        <button 
          className="primary-btn popup-btn-v2 ms-2" 
          type="button" 
          onClick={handleSubmit}
        >
          Save
        </button>
      )
    )
  )}
</div>
                    </div>
                </div>
            {/* </div> */}
        </div>
        </>
  );

}
// PS_AE_16 - Export the AddAssessment component
export default AddEditForm;