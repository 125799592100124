
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { generateOTP, verifyOTP, postErrorLog } from '../Services/api';
import NavHeader from './NavHeader';
import Loader from './Loader';
import { jwtDecode } from "jwt-decode";
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
const UserLogin = () => {
      // PS_Otp_02: SQ_Otp_1.2 - useState

  const [otp, setOtp] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [isValidAccess, setIsValidAccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [otpLockTime, setOtpLockTime] = useState<number | null>(null);
    // PS_Otp_03: SQ_Otp_1.3 - useSearchParams
  const [searchParams] = useSearchParams();
  const emailId: any | null = searchParams.get('emailId');
  const assessmentId: any | null = searchParams.get('assessmentId');
  const navigate = useNavigate();

  // PS_Otp_04: SQ_Otp_1.4 - useEffect
  useEffect(() => {
    const generateOtpIfValid = async () => {
      if (emailId && assessmentId) {
        setIsValidAccess(true);
        const storedOtpLockTime = localStorage.getItem('otpLockTime');
        if (disableButtons) {
          // If buttons are already disabled, it means OTP generation is currently restricted
          return;
        }
        if (storedOtpLockTime) {
          const lockTime = parseInt(storedOtpLockTime, 10);
          if (Date.now() < lockTime) {
            setOtpLockTime(lockTime);
            setDisableButtons(true);
          } else {
            localStorage.removeItem('otpLockTime');
            await initiateOtpGeneration();
          }
        } else {
          await initiateOtpGeneration();
        }
      } else {
        setIsValidAccess(false);
        setError('Invalid access. Please use the correct login link.');
      }
    };
  
    generateOtpIfValid();
    
  }, [emailId, assessmentId, disableButtons]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (otpLockTime) {
      timer = setInterval(() => {
        if (Date.now() >= otpLockTime!) {
          setDisableButtons(false);
          setOtpLockTime(null);
          setError(''); // Clear the error message when lockout period ends
          localStorage.removeItem('otpLockTime');
          clearInterval(timer);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [otpLockTime]);
    // Helper function implementations
  // PS_Otp_05 - PS_Otp_14
  const initiateOtpGeneration = async () => {
    if (!emailId || !assessmentId || disableButtons) {
      return;
    }
    try {
      setLoading(true);
      setError('');
      setOtp('');
      await generateOTP(emailId, assessmentId);
    } catch (error: any) {
      let errorData: object = {
        errorFunction: "initiateOtpGeneration",
        errorMessage: error.message,
        FileName: "UserLogin.tsx"
      }
            // PS_Otp_11 & PS_Otp_12
      await postErrorLog(errorData);
      setError('Failed to generate OTP. Please try again.'); // PS_Otp_24
    } finally {
      setLoading(false); // PS_Otp_13
    }
  };

  //PS_Otp_16 - PS_Otp_27
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // PS_Otp_17
    if (!otp) {
      setError('Please enter the OTP.'); // PS_Otp_27
      return;
    }

  try {
    setLoading(true); // PS_Otp_20
    setError(''); // Resetting the existing error messages
      // PS_Otp_21 & PS_Otp_22
      const verificationResult = await verifyOTP(otp, assessmentId!, emailId!);
    const responseData = verificationResult.data as { 
      success: boolean; 
      status: string; 
      message: string; 
      otp: string;
      Token: string;
    };
    console.log("API Response:", responseData);

      // PS_Otp_23
    if (responseData.success && responseData.message === "Successfully authenticated") {
      console.log("Raw Token:", responseData.Token);

      try {
        // Decode the Base64 token
        const decodedBase64Token = atob(responseData.Token);
        console.log("Decoded Base64 Token:", decodedBase64Token);

        // Split the decoded string
        const [jwtToken, assessmentId, emailId] = decodedBase64Token.split(':');

        console.log("JWT Token:", jwtToken);
        console.log("Token AssessmentId:", assessmentId);
        console.log("Token EmailId:", emailId);
        console.log("URL AssessmentId:", assessmentId);
        console.log("URL EmailId:", emailId);

        if (assessmentId === assessmentId && emailId === emailId) {
          console.log("Token validation successful. Navigating to download page.");
          navigate('/download', { 
            state: { 
              emailId, 
              assessmentId,
              token: responseData.Token
            } 
          });
        } else {
          console.error("Token mismatch:");
          console.error("Token AssessmentId:", assessmentId, "URL AssessmentId:", assessmentId);
          console.error("Token EmailId:", emailId, "URL EmailId:", emailId);
          setError('Authentication failed. Token mismatch.');
        }

        // If you still need to decode the JWT part:
        try {
          const decodedJwt = jwtDecode(jwtToken);
          console.log("Decoded JWT:", decodedJwt);
        } catch (jwtError) {
          console.error("Error decoding JWT part:", jwtError);
        }

      } catch (decodeError) {
        console.error("Token decoding error:", decodeError);
        console.error("Raw token that failed to decode:", responseData.Token);
        setError('Authentication failed. Invalid token format.');
      }
    } else if (responseData.message === "Limit Exceeded. Please try again after 5 minutes.") {
      console.log("OTP limit exceeded. Setting lockout.");
      const lockTime = Date.now() + 5 * 60 * 1000;
      setOtpLockTime(lockTime);
      setDisableButtons(true);
      localStorage.setItem('otpLockTime', lockTime.toString());
      setError(responseData.message);
    } else {
      console.error("API returned an unexpected response:", responseData.message);
      setError(responseData.message);
    }
  } catch (error: any) {
    console.error("Error in handleSubmit:", error);
    let errorData: object = {
      errorFunction: "handleSubmit",
      errorMessage: error.message,
      FileName: "UserLogin.tsx"
    }
          // PS_Otp_11 & PS_Otp_12
    await postErrorLog(errorData);
    setError('An error occurred during OTP verification.');
  } finally {
    setLoading(false); // PS_Otp_26
  }
};
  

  // PS_Otp_28: SQ_Otp_1.25 - Render Method
  return (
    <>
      <Loader isLoading={loading} />
      <div className="container-fluid">
        <div className="row h-100 login-page">
          <div className="col-6">
            <div className="d-flex align-items-center">
              <img src="images/logo.svg" className="logo-img me-2" alt="site logo" />
              <span className="font-medium font-18 primary-text-color">Win Remedy</span>
            </div>

            <div className="px-5 verification-code-section">
              <h3 className="primary-text-color font-40 font-semibold">Verify code</h3>
              <p className="font-16 font-regular mt-3 grey-text-color-dark">
                An authentication code has been sent to your email.
              </p>
              
              <div className="mt-5 position-relative">
                <div className="form-verify-code">
                  <input 
                    id="Password" 
                    type={showPassword ? "text" : "password"} 
                    className="form-control custom-password-field"
                    placeholder=""
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                  <label className="form-label font-14 font-regular custom-floating-postion" htmlFor="floating-code">
                    Enter Code
                  </label>
                  <img 
                    src={showPassword ? "images/pass-hidden.svg" : "images/pass-open.svg"} 
                    className="pass-eye" 
                    alt={showPassword ? "hide password" : "show password"}
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </div>
                {error && <span className="required font-11 font-regular d-block">{error}</span>}
              </div>

              <p className="font-14 font-regular primary-text-color text-end mt-3 mb-4 pb-2">
                Didn't receive a code?  
                {disableButtons ? (
                  <span className="disabled-link"> Resend</span>
                ) : (
                  <a href="#" onClick={()=>initiateOtpGeneration()}> Resend</a>
                )}
              </p>

              {disableButtons || loading ? (
                <button
                  className="primary-button popup-btn-v2 ms-2"
                  type="button"
                  disabled
                >
                  Verify
                </button>
              ) : (
                <button
                  className="primary-btn popup-btn-v2 ms-2"
                  type="button"
                  onClick={handleSubmit}
                >
                  Verify
                </button>
              )}
            </div>
          </div>

          <div className="col-6 banner-right">
            <img 
              src="images/welcome-banner.png" 
              className="login-banner-img" 
              alt="login page banner image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogin;