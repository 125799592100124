
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
interface NavHeaderProps {
  onLogoClick?: () => void;
}
const NavHeader: React.FC<NavHeaderProps> = ({ onLogoClick }) => {
  const [logo, setLogo] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [timeoutPopup, setTimeoutPopup] = useState<boolean>(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState<boolean>(false);
  const [headerLogo, setHeaderLogo] = useState<string>('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const TIMEOUT_DURATION = 1800000; // 30 minutes in milliseconds
  const username = localStorage.getItem('userName') || '';
  const email = localStorage.getItem('emailId') || '';
  const assessmentName = localStorage.getItem('assessmentName') || 'Assessment Name';
  const organizationName2 = localStorage.getItem('organizationName') || 'Organization Name';
  //PS_NH_07 - PS_NH_13
  const startTimer = useCallback(() => {
    return setTimeout(() => {
      setTimeoutPopup(true);
    }, TIMEOUT_DURATION);
  }, [TIMEOUT_DURATION]);
 //PS_NH_06, PS_NH_19 - PS_NH_29
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleUserActivity = () => {
      if (!timeoutPopup && timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = startTimer();
      }
    };

    timeoutId = startTimer();

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);
    window.addEventListener('scroll', handleUserActivity);
    createProfileName();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
      window.removeEventListener('scroll', handleUserActivity);
    };
  }, [startTimer, timeoutPopup]);

  //PS_NH_30 - PS_NH_34
  const createProfileName = () => {
    const nameParts = username.split(' ');
    let logoName = '';
    if (nameParts.length >= 2) {
      logoName = nameParts[0].charAt(0) + nameParts[nameParts.length - 1].charAt(0);
    } else if (nameParts.length === 1) {
      logoName = nameParts[0].substring(0, 2);
    }
    setLogo(logoName.toUpperCase());
  };
 
  //PS_NH_35, PS_NH_52 - PS_NH_64
  const handleLogout = () => {
    try {
      sessionStorage.clear();
      localStorage.clear();
      setTimeoutPopup(false);
      setShowLogoutConfirmation(false);
      if (location.pathname === '/downloadURLPage') {
        navigate('/userLogin');
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  //PS_NH_36, PS_NH_43, PS_NH_44
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    if (location.pathname === '/dashboard' || location.pathname === '/device' ) {
      setHeaderLogo(assessmentName);
      setOrganizationName(organizationName2);
    } else {
      setHeaderLogo('');
      setOrganizationName('');
    }
  }, [location]);
 
  if (location.pathname === '/organizationGrid') {
    return (
      <>
      <nav className="navbar navbar-expand-lg custom-nav">
        <div className="container-fluid custom-container">
        <div 
              className="navbar-brand d-flex logo-with-text align-items-center cursor-pointer" 
              onClick={onLogoClick}
            >
              <img src="images/logo.svg" alt="logo" className="header-logo" />
              <h3 className="ms-2 primary-text-color font-medium font-18">Win Remedy</h3>
            </div>
          <div className="profile-section">
            <button type="button" onClick={toggleDropdown} className="btn border-0 shadow-none background-white p-0">
              <p className="profile-btn profile-text-color profile-bg font-semibold font-16">{logo}</p>
            </button>
            {showDropdown && (
              <div className="dropdown-menu dropdown-menu-end text-center border-0 logout-container show">
                <div className="logout-section">
                  <p className="profile-btn profile-text-color profile-bg font-semibold font-28 me-3">{logo}</p>
                  <div className="me-1">
                    <p className="font-16 font-medium m-0 color-black text-start">{username}</p>
                    <span className="font-9 font-medium secondary-text-color mt-1 mb-0 text-decoration-none grey-text-color-light">{email}</span>
                  </div>
                  <img src="/images/logout-icon.svg" alt="logout-icon" className="logout-icon ms-4 cursor-pointer" onClick={() => setShowLogoutConfirmation(true)} />
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
      {(showLogoutConfirmation || timeoutPopup) && (
          <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
              <div className="modal-content custom-popup border-0">
                <div className="modal-body p-5 pb-4 text-center">
                  <img src="/images/danger-icon.svg" alt="danger-icon" className="danger-icon"/>
                  {timeoutPopup ? (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">Your Session has been timed out due to inactivity. Please login again into the application.</p>
                      <button className="primary-btn popup-btn mt-3 mb-3" type="button" onClick={handleLogout}>Ok</button>
                    </>
                  ) : (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">Are you sure you want to Logout ?</p>
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 pt-1">
                        <button className="outline-btn popup-btn me-2" type="button" onClick={() => setShowLogoutConfirmation(false)}>No</button>
                        <button className="primary-btn popup-btn ms-2" type="button" onClick={handleLogout}>Yes</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        </>
    );
  }

  if (location.pathname === '/dashboard' || location.pathname === '/device') {
    return (
      <>
      <nav className="navbar navbar-expand-lg custom-nav">
        <div className="container-fluid custom-container">
          <div className="d-flex align-items-center">
            <a className="navbar-brand" href="#">
              <span className="d-flex logo-with-text">
                <img src="images/back-arrow-icon.svg" alt="back-arrow-icon" className="header-back-arrow-icon" onClick={() => navigate('/organizationGrid')}/>
              </span>
            </a>
            <h3 className="ms-3 black-color font-regular font-20" >{assessmentName}</h3>
          </div>
          <div className="d-flex align-items-center gap-3">
            <div className="organization-section">
              <p className="font-regular font-12 grey-text-color-dark">Organization Name: <span className="font-medium primary-text-color">{organizationName2}</span></p>
            </div>
            <div className="profile-section">
              <button type="button" onClick={toggleDropdown} className="btn border-0 shadow-none background-white p-0">
                <p className="profile-btn profile-text-color profile-bg font-semibold font-16">{logo}</p>
              </button>
              {showDropdown && (
                <div className="dropdown-menu dropdown-menu-end text-center border-0 logout-container show">
                  <div className="logout-section">
                    <p className="profile-btn profile-text-color profile-bg font-semibold font-28 me-3">{logo}</p>
                    <div className="me-1">
                      <p className="font-16 font-medium m-0 color-black text-start">{username}</p>
                      <span className="font-9 font-medium secondary-text-color mt-1 mb-0 text-decoration-none grey-text-color-light">{email}</span>
                    </div>
                    <img src="/images/logout-icon.svg" alt="logout-icon" className="logout-icon ms-4 cursor-pointer" onClick={() => setShowLogoutConfirmation(true)} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
      {(showLogoutConfirmation || timeoutPopup) && (
          <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
              <div className="modal-content custom-popup border-0">
                <div className="modal-body p-5 pb-4 text-center">
                  <img src="/images/danger-icon.svg" alt="danger-icon" className="danger-icon"/>
                  {timeoutPopup ? (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">Your Session has been timed out due to inactivity. Please login again into the application.</p>
                      <button className="primary-btn popup-btn mt-3 mb-3" type="button" onClick={handleLogout}>Ok</button>
                    </>
                  ) : (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">Are you sure you want to Logout ?</p>
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 pt-1">
                        <button className="outline-btn popup-btn me-2" type="button" onClick={() => setShowLogoutConfirmation(false)}>No</button>
                        <button className="primary-btn popup-btn ms-2" type="button" onClick={handleLogout}>Yes</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  if (location.pathname === '/downloadURLPage') {
    return (
      <>
       <nav className="navbar navbar-expand-lg custom-nav">
        <div className="container-fluid custom-container">
          <a className="navbar-brand d-flex logo-with-text align-items-center" href="#">
            <img src="images/logo.svg" alt="logo" className="header-logo" />
            <h3 className="ms-2 primary-text-color font-medium font-18">Win Remedy</h3>
          </a>
        </div>
      </nav>
      {(showLogoutConfirmation || timeoutPopup) && (
          <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog modal-dialog-centered modal-l custom-popup-width-half">
              <div className="modal-content custom-popup border-0">
                <div className="modal-body p-5 pb-4 text-center">
                  <img src="/images/danger-icon.svg" alt="danger-icon" className="danger-icon"/>
                  {timeoutPopup ? (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">Your Session has been timed out due to inactivity. Please login again into the application.</p>
                      <button className="primary-btn popup-btn mt-3 mb-3" type="button" onClick={handleLogout}>Ok</button>
                    </>
                  ) : (
                    <>
                      <p className="font-16 line-height-32 font-regular mt-4 mb-1 secondary-text-color-dark">Are you sure you want to Logout ?</p>
                      <div className="d-flex align-items-center justify-content-center mt-3 mb-3 pt-1">
                        <button className="outline-btn popup-btn me-2" type="button" onClick={() => setShowLogoutConfirmation(false)}>No</button>
                        <button className="primary-btn popup-btn ms-2" type="button" onClick={handleLogout}>Yes</button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        </>
    );
  }
  // Default return for other routes
  return (
    <header className="flex justify-between items-center p-4 bg-gray-100">
     
    </header>
  );
};

export default NavHeader;