
import React, { useEffect } from "react";

interface LoaderProps {
    isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = (props) => {
    useEffect(() => {
        const body = document.body;
        if (props.isLoading) {
            body.classList.add('loading');
        } else {
            body.classList.remove('loading');
        }

        return () => {
            body.classList.remove('loading');
        };
    }, [props.isLoading]);

    if (props.isLoading) {
        return (
            <div className="pageLoaderOverlay">
            <div className="page-loader-parent">
                <div className="page-loader">
                    <img src="images/Win Remedy page loader.gif" alt="page-loader" className="page-loader-img" />
                </div>
            </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Loader;