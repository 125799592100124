import AddEditForm from './AddEditForm';
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import { useNavigate } from 'react-router-dom';
import { fetchOrganizationData, fetchFilterOptions,getAssessmentDetails, getDeviceDetails, postErrorLog } from '../Services/api';
import {
  Filter,
  Sort,
  Organization,
  Assessment,
  FilterOption,
  OrganizationResponse,
  EditData,
  FilterOptionsResponse,
  DeviceDetail,
  GridCount,
  FetchOrganizationRequest,
  ExportRequest,
  ApiResponse
} from '../Interface/GridInterface';
import { AxiosResponse } from 'axios';
import NavHeader from './NavHeader';
import Loader from './Loader';

// Your component
const OrganizationGrid: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [loadMore, setLoadMore] = useState<number>(10);
  const [gridCount, setGridCount] = useState<GridCount | 0>(0);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState<FilterOption[]>([]);
  const [organizationOptions, setOrganizationOptions] = useState<string[]>([]);
  const [filter, setFilter] = useState<Filter>({
    status: "",
    organization: "",
    fromDate: "",
    toDate: ""
  });
  const [tempFilter, setTempFilter] = useState<Filter>({
    status: "",
    organization: "",
    fromDate: "",
    toDate: ""
  });
  const [tempSearch, setTempSearch] = useState<string>('');
  const [exportRequest, setExportRequest] = useState<ExportRequest>({
    assessmentId: 0,
    loadMore: 0,
    search: "",
    sort: {
      column: "device_name",
      order: "asc"
    },
    filter: {
      manufacturer: "",
      Status: ""
    }
  });
  
  const [sort, setSort] = useState<Sort>({ sortColumn: 'organization', sortDirection: 'asc' });
  const [frontendSort, setFrontendSort] = useState<{ column: string; direction: 'asc' | 'desc' }>({
    column: '',
    direction: 'asc'
  });
  
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showAddAssessmentPopup, setShowAddAssessmentPopup] = useState<{ show: boolean; editData: EditData | null }>({ show: false, editData: null });
  const navigate = useNavigate();
  const handleLogoClick = () => {
    // Reload the current page
    window.location.reload();
  };
  //PS_CP_15
  useEffect(() => {
    fetchData();
    fetchFilterOptionsData();
  }, [loadMore, search, filter, sort]);

  //PS_CP_19, PS_CP_21 - PS_CP_24
  const fetchData = async () => {
    setLoading(true);
    try {
      // PS_CP_20
      const requestData: FetchOrganizationRequest = {
        search,
        filter,
        sort,
        loadMore
      };
   
      
      //PS_CP_20
      const response: AxiosResponse = await fetchOrganizationData(requestData);
     
      if (response.status === 200 && response.data.status === "success") {
        const responseData = response.data.data;  // Access the nested data
        
        if (responseData.organizationDetails && Array.isArray(responseData.organizationDetails)) {
         
          setOrganizations(responseData.organizationDetails);
         
  
          // Flatten assessments from all organizations
          const allAssessments = responseData.organizationDetails.flatMap((org: { assessments: any; }) => org.assessments || []);
          setAssessments(allAssessments);
        } else {
          console.error("organizationDetails is not an array or doesn't exist:", responseData.organizationDetails);
          setOrganizations([]);
          setAssessments([]);
        }
  
        // Check if totalCount and recordsPerPage exist
        if ('totalCount' in responseData && 'recordsPerPage' in responseData) {
          setGridCount({
            totalCount: responseData.totalCount,
            recordsPerPage: responseData.recordsPerPage
          });
          
        } else {
          let errorData:object = {
            errorFunction: "fetchData",
            errorMessage: "totalCount or recordsPerPage is missing:"+ responseData,
            FileName: "OrganizationGrid.tsx"
        }
        postErrorLog(errorData);
          setGridCount({ totalCount: 0, recordsPerPage: 0 });
        }
  
      } else {
        let errorData:object = {
          errorFunction: "fetchData",
          errorMessage: "Error fetching organization data: Status"+response.status+"API Status:"+response.data.status,
          FileName: "OrganizationGrid.tsx"
      }
      postErrorLog(errorData);
      }
    } catch (error:any) {
      // PS_CP_25
      // Assuming `postErrorLog` is an actual function you have elsewhere in your codebase that handles error logging.
        let errorData:object = {
            errorFunction: "fetchData",
            errorMessage: error.message,
            FileName: "OrganizationGrid.tsx"
        }
        postErrorLog(errorData);
    }
    setLoading(false);
  };
  //PS_CP_26 - PS_CP_31
  const fetchFilterOptionsData = async () => {
    setLoading(true);
    try {
      const response:AxiosResponse = await fetchFilterOptions();
      
      if (response.status === 200) {
        // PS_CP_29
        setOrganizationOptions(response.data.data.organizations.map((org: { organizationName: any; }) => org.organizationName));

        // PS_CP_30
        setStatusOptions(response.data.data.status);
      } else {
        // PS_CP_31
        console.error("Error fetching filter options: Status", response.status);
      }
    } catch (error:any) {
      // PS_CP_32
      // Assuming `postErrorLog` is an actual function you have elsewhere in your codebase that handles error logging.
      let errorData:object = {
        errorFunction: "fetchFilterOptionsData",
        errorMessage: error.message,
        FileName: "OrganizationGrid.tsx"
    }
      postErrorLog(errorData);
    }
    setLoading(false);
  };

  // PS_CP_36 & PS_CP_54
  const fetchEditAssessmentDetails = async (organizationId: string, assessmentId: string) => {
    setLoading(true);
    try {
      // const defaultAssessmentId = assessmentId || '1';
      const response:AxiosResponse  = await getAssessmentDetails({
        organizationId: organizationId,
        assessmentId: assessmentId
      });
      if (response.status === 200) {
        const assessmentDetails = response.data;
        
        const updatedEditData: EditData = {
          assessmentId: assessmentDetails.assessmentId,
          organizationId: assessmentDetails.organizationId,
          organizationName: assessmentDetails.organization_name,
          organizationLogo: assessmentDetails.organization_logo_url,
          assessmentName: assessmentDetails.assessment_name,
          management_type: assessmentDetails.management_type,
          ldap_server_name: assessmentDetails.ldap_server_name,
          ldap_user_name: assessmentDetails.ldap_user_name,
          ldap_password: assessmentDetails.ldap_password,
          deviceManagement: assessmentDetails.management_type,
          activeCredentials: {
            ldapServerName: assessmentDetails.ldap_server_name,
            username: assessmentDetails.ldap_user_name,
            password: assessmentDetails.ldap_password
          },
          UnmanagedCredentials: []
        };
  
        setShowAddAssessmentPopup({ show: true, editData: updatedEditData });
      }else {
        // PS_CP_49
        console.error('Error fetching assessment details: ', response.status);
        let errorData:object = {
          errorFunction: "fetchEditAssessmentDetails",
          errorMessage: "Error fetching assessment details: "+ response.status,
          FileName: "OrganizationGrid.tsx"
      }
        postErrorLog(errorData);
      }
    } catch (error:any) {
      // PS_CP_52 & PS_CP_53 & PS_CP_54
      let errorData:object = {
        errorFunction: "fetchEditAssessmentDetails",
        errorMessage: error.message,
        FileName: "OrganizationGrid.tsx"
    }
      postErrorLog(errorData);
    }
    setLoading(false);
  };

  //PS_CP_61 - PS_CP_80
  const handleExportDeviceDetails = async (assessmentId: string) => {
    setLoading(true);
    
    try {
      const updatedExportRequest = {
        ...exportRequest,
        assessmentId: parseInt(assessmentId),
      };
      
      const response: AxiosResponse = await getDeviceDetails(updatedExportRequest);
      
      if (response.status === 200 && response.data.status === "Success") {
        const deviceDetailsArray = response.data.data.deviceDetails;
  
        // Create workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('DeviceDetails');
  
        // Define columns
        worksheet.columns = [
          { header: 'Username', key: 'username', width: 15 },
          { header: 'Device Name', key: 'deviceName', width: 15 },
          { header: 'Status', key: 'status', width: 15 },
          { header: 'Manufacturer', key: 'manufacturer', width: 15 },
          { header: 'Model', key: 'model', width: 15 },
          { header: 'System Type', key: 'systemType', width: 15 },
          { header: 'CPU Processor', key: 'cpuProcessor', width: 20 },
          { header: 'CPU Core', key: 'cpuCore', width: 10 },
          { header: 'HDD', key: 'hdd', width: 10 },
          { header: 'TPM Status', key: 'tpmStatus', width: 15 },
          { header: 'Memory', key: 'memory', width: 10 },
          { header: 'Secure Boot', key: 'secureBoot', width: 15 },
          { header: 'OS', key: 'os', width: 20 },
          { header: 'Installed App Count', key: 'installedAppCount', width: 20 },
          { header: 'Supported App Count', key: 'supportedAppCount', width: 20 },
          { header: 'Unsupported App Count', key: 'unsupportedAppCount', width: 20 },
          { header: 'Hardware Status', key: 'hardwareStatus', width: 15 },
          { header: 'Software Status', key: 'softwareStatus', width: 15 },
        ];
  
        // Apply bold style to the header row
        worksheet.getRow(1).font = { bold: true };
  
        // Add data
        deviceDetailsArray.forEach((device: any) => {
          worksheet.addRow({
            username: device.userName,
            deviceName: device.deviceName,
            status: device.status,
            manufacturer: device.deviceDetails.manufacturer,
            model: device.deviceDetails.model,
            systemType: device.deviceDetails.systemType,
            cpuProcessor: device.deviceDetails.hardwareDetailsDto.cpuProcessor,
            cpuCore: device.deviceDetails.hardwareDetailsDto.cpuCore,
            hdd: device.deviceDetails.hardwareDetailsDto.hdd,
            tpmStatus: device.deviceDetails.hardwareDetailsDto.tpmStatus ? "Yes" : "No",
            memory: device.deviceDetails.hardwareDetailsDto.memory,
            secureBoot: device.deviceDetails.hardwareDetailsDto.secureBoot ? "Yes" : "No",
            os: device.deviceDetails.softwareDetailsDto.operatingSystem,
            installedAppCount: device.deviceDetails.softwareDetailsDto.numberOfInstalledApps,
            supportedAppCount: device.deviceDetails.softwareDetailsDto.numberOfSupportedApps,
            unsupportedAppCount: device.deviceDetails.softwareDetailsDto.numberOfUnsupportedApps,
            hardwareStatus: device.deviceDetails.hardwareDetailsDto.status,
            softwareStatus: device.deviceDetails.softwareDetailsDto.status,
          });
        });
  
        // Generate buffer
        const buffer = await workbook.xlsx.writeBuffer();
  
        // Create Blob and save file
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'DeviceDetails.xlsx';
        a.click();
        URL.revokeObjectURL(url);
  
      } else {
        console.error("Error in API response:", response);
      }
    } catch (error:any) {
      let errorData:object = {
        errorFunction: "fetchData",
        errorMessage: error.message,
        FileName: "OrganizationGrid.tsx"
      }
      postErrorLog(errorData);
      console.error('Download error:', error);
    }
  
    setLoading(false);
  };
  
  const bindRecordOption = () => {
    if (typeof gridCount === 'object' && gridCount !== null) {
        let maxValue: number = (gridCount.totalCount) > 50 ? 50 : Math.ceil((gridCount.totalCount) / 10) * 10;
        let dropdownValues: number[] = [];
        for (let i = 10; i <= maxValue; i += 10) {
            dropdownValues.push(i);
        }
        return dropdownValues.map(value => (
            <option key={value} value={value}>
                {value}
            </option>
        ));
    }
    return null; // or return an empty array, or some default options
};

    // PS_CP_85 & PS_CP_86 & PS_CP_87
    const toggleRow = (index: number) => {
      setExpandedRowIndex(prevIndex => prevIndex === index ? null : index);
    };
      
      // PS_CP_89 & PS_CP_90
      const handleFilterChange = (key: string, value: any) => {
        setTempFilter(prevFilter => ({
          ...prevFilter,
          [key]: value
        }));
      };

    // PS_CP_93 & PS_CP_96
    const applyFilter = () => {
      setFilter(tempFilter); // Set the actual filter with temporary values
      setShowFilter(false); // Hide the filter UI panel
    };
      
      // PS_CP_98 & PS_CP_99 & PS_CP_100
      const cancelFilter = () => {
        setTempFilter({
          status: '',
          organization: '',
          fromDate: '',
          toDate: '',
        });
        setFilter({
          status: '',
          organization: '',
          fromDate: '',
          toDate: '',
        });
        setShowFilter(false); // Hide the filter UI panel
      };
    
      // PS_CP_102, PS_CP_103 & PS_CP_104
      const handleSort = (column: string) => {
        if (column === 'organization') {
          // For Organizations, update the sort state and fetch data from backend
          setSort(prevSort => ({
            sortColumn: column,
            sortDirection: prevSort.sortColumn === column && prevSort.sortDirection === 'asc' ? 'desc' : 'asc'
          }));
        } else {
          // For other columns, update the frontendSort state
          setFrontendSort(prevSort => ({
            column,
            direction: prevSort.column === column && prevSort.direction === 'asc' ? 'desc' : 'asc'
          }));
        }
      };
      const sortAssessments = (assessments: Assessment[]) => {
        return [...assessments].sort((a, b) => {
          if (frontendSort.column === 'createdDate') {
            return frontendSort.direction === 'asc' 
              ? new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
              : new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
          }
          if (frontendSort.column === 'assessmentProgress') {
            const progressA = (a.completedDeviceCount / a.totalDeviceCount) * 100;
            const progressB = (b.completedDeviceCount / b.totalDeviceCount) * 100;
            return frontendSort.direction === 'asc' ? progressA - progressB : progressB - progressA;
          }
          if (frontendSort.column === 'status') {
            return frontendSort.direction === 'asc' 
              ? a.status.localeCompare(b.status)
              : b.status.localeCompare(a.status);
          }
          return 0;
        });
      };
  
  // PS_CP_107 & PS_CP_108
  const handleSearch = (value: string) => {
    setTempSearch(value);
  };
  
  const getStatusClass = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'completed';
      case 'In Progress':
        return 'in-progress';
      case 'Yet to Start':
        return 'yet-to-start2';
      default:
        return '';
    }
  };

  // PS_CP 110 & PS_CP 111
  const handleLoadMore = () => {
    setLoadMore(prevLoadMore => prevLoadMore + 10); // Increase loadMore by 10
  };
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <>
    <NavHeader onLogoClick={handleLogoClick} />
     <Loader isLoading={loading} /> 
    <div className="container-fluid custom-container">
    <div className="d-flex align-items-center justify-content-between my-4">
        <h2 className="font-20 font-regular black-color mb-0">Organization Details</h2>
        <div className="d-flex align-items-center justify-content-center gap-3">
        <div className="position-relative custom-input-field p-2">
  <input 
    type="search" 
    className="border-0 font-13 font-regular ps-2 pe-4" 
    placeholder="Search"
    value={tempSearch}
    onChange={(e) => handleSearch(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        setSearch(tempSearch.trim());
      }
    }}
  />
  <button 
    title='Search'
    className="btn bg-transparent py-3 search-btn"
    onClick={() => {
      setSearch(tempSearch.trim());
    }}
  >
  </button>
</div>

          <div className="position-relative">
            <button 
              className="common-btn d-flex align-items-center" 
              type="button"
              onClick={() => setShowFilter(!showFilter)}
            >
              <img src="images/config-icon.svg" alt="filter-icon" className="filt-icon" />
            </button>
            {showFilter && (
              <div className="dropdown-menu dd-width shadow-sm p-4 border-0" style={{display: 'block'}}>
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="font-16 font-semibold table-title-color mb-0">Filter</h5>
                    <button title='button' type="button" className="btn-close font-12" onClick={() => setShowFilter(false)}></button>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="Organization" className="form-label table-title-color font-14 font-regular">
                          Organization
                        </label>
                        <select 
                        name="organization"
                        title='Org'
                          className="form-select"
                          value={tempFilter.organization}
                          onChange={(e) => handleFilterChange('organization', e.target.value)}
                        >
                          <option value="">Select Organization</option>
                          {organizationOptions && organizationOptions.map((org, index) => (
                          <option key={index} value={org}>{org}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="Status" className="form-label table-title-color font-14 font-regular">
                          Status
                        </label>
                        <select 
                        name="status"
                        title='status'
                          className="form-select"
                          value={tempFilter.status}
                          onChange={(e) => handleFilterChange('status', e.target.value)}
                        >
                          <option value="">Select Status</option>
                          <option value="Yet to Start">Yet to Start</option>
                          <option value="In Progress">In Progress</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div>
                    </div>

                    <h5 className="font-14 font-medium table-title-color my-3">Date Range</h5>

                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="From" className="form-label text-black font-13 font-semibold">From</label>
                        <input 
                        name='fromDate'
                          type="date" 
                          id="From"
                          className="form-control font-14 font-medium custom-date cust-input-sty"
                          value={tempFilter.fromDate}
                          onChange={(e) => handleFilterChange('fromDate', e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <div className="mb-3">
                        <label htmlFor="To" className="form-label text-black font-13 font-semibold">To</label>
                        <input 
                        name='toDate'
                          type="date" 
                          id="To"
                          className="form-control font-14 font-medium custom-date cust-input-sty"
                          value={tempFilter.toDate}
                          onChange={(e) => handleFilterChange('toDate', e.target.value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-end mt-3">
                    <button 
                      className="outline-btn popup-btn-v2 mt-2 mb-3 me-2 px-4 p-2 font-14 font-medium"
                      type="button"
                      onClick={cancelFilter}
                    >
                      Clear All
                    </button>
                    <button 
                      className="primary-btn popup-btn-v2 mt-2 mb-3 ms-2 px-3 font-14 font-medium"
                      type="button"
                      onClick={applyFilter}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button 
     className="gradient-btn font-14 font-medium border-0 d-flex align-items-center gap-2"
     onClick={() =>  setShowAddAssessmentPopup({ show: true, editData: null })}
   >
     <img src="./images/plus-white-icon.svg" alt="Plus-icon" />
     Add Assessment
   </button>
        </div>
      </div>
      {(() => {
      const hasSearchOrFilter = search.trim() !== '' || Object.values(filter).some(value => value !== '');

if (gridCount === 0 || (typeof gridCount === 'object' && gridCount.totalCount === 0)) {
  if (!hasSearchOrFilter) {
    return (
      <div className="d-flex align-items-center justify-content-center flex-column py-5">
        <img src="images/no-data-found-img.svg" className="mb-5" alt="no-data-found" />
        <p className="font-semibold font-16 text-dark mb-4">No Organization Found</p>
        <p className="font-regular font-13 grey-text-color-light mb-4 text-center">
          Add Organization to add Assessments to list down
          <span className="text-break d-block">here.</span>
        </p>
        <button 
          className="gradient-btn font-14 font-medium border-0 d-flex align-items-center gap-2"
          onClick={() => setShowAddAssessmentPopup({ show: true, editData: null })}
        >
          <img src="./images/plus-white-icon.svg" alt="Plus-icon" />
          Add Assessment
        </button>
      </div>
    );
  } else if (typeof gridCount === 'object' && gridCount.recordsPerPage === 0) {
    return (
      <div className="table-responsive table-border Organization-table">
        <table className="table">
          <thead>
            <tr>
              <th className="text-start border-0 rounded-3">
                Organizations 
                <span className="ms-1 cursor-pointer" onClick={() => handleSort('organization')}>
                  {sort.sortColumn === 'organization' && (
                    <img 
                      src={sort.sortDirection === 'asc' ? "images/sort-down-arrow.svg" : "images/sort-up-arrow.svg"} 
                      alt={sort.sortDirection === 'asc' ? "sort-down-arrow" : "sort-up-arrow"}
                    />
                  )}
                  {sort.sortColumn !== 'organization' && (
                    <img src="images/sort-down-arrow.svg" alt="sort-down-arrow" />
                  )}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-transparent">
              <td colSpan={1}>
                <div className="d-flex align-items-center justify-content-center flex-column py-5">
                  <img src="images/no-data-found-img.svg" className="mb-5" alt="no-data-found" />
                  <p className="font-semibold font-16 text-dark mb-4">No Organization Found</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

return (
<>
  <div className="table-responsive table-border Organization-table">
    <table className="table">
      <thead>
        <tr>
          <th className="text-start border-0 rounded-3">
            Organizations 
            <span className="ms-1 cursor-pointer" onClick={() => handleSort('organization')}>
              {sort.sortColumn === 'organization' && (
                <img 
                  src={sort.sortDirection === 'asc' ? "images/sort-down-arrow.svg" : "images/sort-up-arrow.svg"} 
                  alt={sort.sortDirection === 'asc' ? "sort-down-arrow" : "sort-up-arrow"}
                />
              )}
              {sort.sortColumn !== 'organization' && (
                <img src="images/sort-down-arrow.svg" alt="sort-down-arrow" />
              )}
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        {organizations && organizations.length > 0 ? (
          organizations.map((org, index) => (
            <tr key={org.organizationName}>
              <td className="p-0 border-0">
                <div className="table-responsive theme-table bg-white">
                  <table className="table table-borderless mb-2 cust-accordion-table">
                    <thead>
                      <tr className="border-0">
                        <th 
                          className={`text-nowrap cust-table-accordion-head cursor-pointer py-3 bg-white ${expandedRowIndex === index ? '' : 'collapsed'}`}
                          onClick={() => toggleRow(index)}
                        >
                          <span className="d-flex align-items-center gap-3">
                            <button title='button' className="table-accordion-decor border-0 d-flex justify-content-center align-items-center flex-row"></button>
                            <img 
  src={
    org.organizationLogoURL &&
    org.organizationLogoURL !== "PublicAccessNotPermitted" &&
    (org.organizationLogoURL.startsWith('http') || org.organizationLogoURL.startsWith('/'))
      ? org.organizationLogoURL
      : "/images/profile-logo.svg"
  }
  alt={`${org.organizationName} logo`} 
  className="profile-logo" 
/>
                            <span className="font-16 font-regular secondary-text-color-dark">{org.organizationName}</span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    {expandedRowIndex === index && (
                      <tbody className="position-relative">
                        <tr className="collapse show" id={org.organizationName}>
                          <td colSpan={2} className="custom-acc-body pe-0">
                            <span className="table-responsive theme-table bg-white d-inline-block w-100 custom-inner-table">
                              <table className="table table-borderless mb-0 accordion-inner-table">
                                <thead>
                                  <tr>
                                    <th className="text-nowrap">Assessments</th>
                                    <th className="text-nowrap">
                                      Created Date
                                      <span className="ms-1 cursor-pointer" onClick={() => handleSort('createdDate')}>
                                      {frontendSort.column === 'createdDate' && (
      <img 
        src={frontendSort.direction === 'asc' ? "images/sort-down-arrow.svg" : "images/sort-up-arrow.svg"} 
        alt={frontendSort.direction === 'asc' ? "sort-down-arrow" : "sort-up-arrow"}
      />
    )}
    {frontendSort.column !== 'createdDate' && (
      <img src="images/sort-down-arrow.svg" alt="sort-down-arrow" />
    )}
                                      </span>
                                    </th>
                                    <th className="text-nowrap">
                                      Assessment Progress
                                      <span className="ms-1 cursor-pointer" onClick={() => handleSort('assessmentProgress')}>
                                      {frontendSort.column === 'assessmentProgress' && (
      <img 
        src={frontendSort.direction === 'asc' ? "images/sort-down-arrow.svg" : "images/sort-up-arrow.svg"} 
        alt={frontendSort.direction === 'asc' ? "sort-down-arrow" : "sort-up-arrow"}
      />
    )}
    {frontendSort.column !== 'assessmentProgress' && (
      <img src="images/sort-down-arrow.svg" alt="sort-down-arrow" />
    )}
                                      </span>
                                    </th>
                                    <th className="text-nowrap text-center">
                                      Status
                                      <span className="ms-1 cursor-pointer" onClick={() => handleSort('status')}>
                                      {frontendSort.column === 'status' && (
      <img 
        src={frontendSort.direction === 'asc' ? "images/sort-down-arrow.svg" : "images/sort-up-arrow.svg"} 
        alt={frontendSort.direction === 'asc' ? "sort-down-arrow" : "sort-up-arrow"}
      />
    )}
    {frontendSort.column !== 'status' && (
      <img src="images/sort-down-arrow.svg" alt="sort-down-arrow" />
    )}
                                      </span>
                                    </th>
                                    <th className="text-nowrap text-center">Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {org.assessments && org.assessments.length > 0 ? (
                                     sortAssessments(org.assessments).map((assessment) => (
                                      <tr key={assessment.assessmentId}>
                                        <td>
                                          <a 
                                            href="#" 
                                            className="text-nowrap blue-text-color text-decoration-none"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              navigate('/dashboard', { state: { assessmentName: assessment.assessmentName, assessmentId: assessment.assessmentId, organizationName: org.organizationName, managementType: assessment.managementType } });
                                            }}
                                          >
                                            {assessment.assessmentName}
                                          </a>
                                        </td>
                                        <td>{formatDate(assessment.createdDate)}</td>
                                        <td>
                                          <div className="d-flex align-items-center gap-4">
                                            <div className="custom-progress-bar">
                                              <span 
                                                style={{width: `${Math.min(100, (assessment.completedDeviceCount / assessment.totalDeviceCount * 100) || 0)}%`}}
                                                className="progress-lvl"
                                              ></span>
                                            </div>
                                            <label> {Math.min(100, Math.round((assessment.completedDeviceCount / assessment.totalDeviceCount * 100) || 0))}%</label>
                                          </div>
                                        </td>
                                        <td className="">
  <div className="d-flex align-items-center justify-content-center">
    <span className={`status-badge ${getStatusClass(assessment.status)} d-flex align-items-center gap-2 text-nowrap font-12 font-regular`}>
      <span className="status-legend"></span>
      {assessment.status}
    </span>
  </div>
</td>
                                        <td className="text-nowrap text-center">
                                          <div className="d-flex align-items-center justify-content-center">
                                            <button 
                                              className="btn border-0 common-btn p-2 py-1"
                                              onClick={() => fetchEditAssessmentDetails(org.organizationId?.toString(), assessment.assessmentId)}
                                            >
                                              <img src="./images/edit-icon.svg" alt="edit-icon" />
                                            </button>
                                            <div className="download-option-dropdown position-relative">
                                              <button 
                                                type="button" 
                                                className="btn border-0 common-btn p-2 py-1 position-relative font-14"
                                                data-bs-toggle="dropdown" 
                                                aria-expanded="false"
                                              >
                                                <img src="./images/download-icon.svg" alt="download-icon" />
                                              </button>
                                              <ul className="dropdown-menu dropdown-menu-end border-0 dd-position export-dropdown py-1">
                                                <li 
                                                  className="font-regular font-13 secondary-text-color-dark border-top-0"
                                                  onClick={() => handleExportDeviceDetails(assessment.assessmentId)}
                                                >
                                                  Full Report
                                                </li>
                                                {assessment.managementType === "Active Directory" && (
                                                  <li 
                                                    className="font-regular font-13 secondary-text-color-dark"
                                                    onClick={() => navigate('/downloadURLPage', { state: assessment.assessmentId })}
                                                  >
                                                    Download App
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={5}>No assessments available</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td>No organizations available</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
  {typeof gridCount === 'object' && gridCount !== null && gridCount.totalCount > 0 && (
    <div className="d-flex align-items-center justify-content-between gap-3 bottom-0 position-relative cust-width">
      <div className="d-flex align-items-center justify-content-center flex-nowrap gap-3">
        <span className="font-regular font-13 secondary-text-color-light">
          Showing <span>{organizations.length}</span> of <span>{gridCount?.totalCount}</span> records
        </span>
        <div className="d-flex align-items-center gap-2">
          <select 
            title='load'
            className="form-field p-1 px-2"
            value={loadMore}
            onChange={(e) => setLoadMore(Number(e.target.value))}
          >
            {bindRecordOption()}
          </select>
          <span className="font-regular font-13 secondary-text-color-light">lines per page</span>
        </div>
      </div>
      {organizations.length >= 10 && organizations.length < gridCount.totalCount && (
        <button 
          className="outline-btn-2 py-2 rounded-2 font-14 font-regular mb-3" 
          type="button"
          onClick={handleLoadMore}
        >
          Load More
        </button>
      )}
    </div>
  )}
</>
)
})()}




{showAddAssessmentPopup.show && (
  <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
    <div className="modal-dialog">
      <AddEditForm 
        isPopup={true}
        editData={showAddAssessmentPopup.editData}
        onClose={() => {
          setShowAddAssessmentPopup({ show: false, editData: null });
        }}
        onSubmitSuccess={() => {
          setShowAddAssessmentPopup({ show: false, editData: null });
          fetchData(); // Refresh your grid data here
        }}
      />
    </div>
  </div>
)}
    </div>
  </>
  );
};

export default OrganizationGrid;